import React from 'react';
import Header from '../../Shared/Header';
import BreadCums from '../../Shared/BreadCums';
import PricePlan from '../../Shared/PricePlan';
import PriceFaq from './PriceFaq';
import LatestBlog from '../../Shared/LatestBlog';
import Apps from '../../Shared/Apps';
import Footer3 from '../../Shared/Footer3';
import Home4Price from "../HomeFour/Home4Price";

const PriceStripeTable = () => {
    return (
        <>
            <Header
                parentMenu='pages'
                headerClass='sc-header-section inner-header-style'
                signUpBtn='inner-btn'
            />
            <BreadCums pageTitle='Pricing Plans' />
            {/*<PricePlan />*/}
           <StripePricingTable/>
            <PriceFaq />{/*
            <LatestBlog />
            <Apps />*/}
            <Footer3 />
        </>
    );
};

export default PriceStripeTable;

export const StripePricingTable = () => {
   React.useEffect(() => {
      const script = document.createElement('script');
      script.src = 'https://js.stripe.com/v3/pricing-table.js';
      script.async = true;
      document.body.appendChild(script);
      
      return () => {
         document.body.removeChild(script);
      };
   }, []);
   
   return (
      <div style={{paddingTop:50, backgroundColor:'#F0F0F0'}}>
      <stripe-pricing-table
         pricing-table-id="prctbl_1NDGbhAhhHl6AdBSdXhS1QTL"
         publishable-key="pk_live_51N6Fu4AhhHl6AdBS1BMzk1pSvU0pJrJkLM5wO1hzANRwNHuJe8cSsVig33C8MPyK0Z7YassD5jzZhGPptaH84wRa00romdSia3"
      ></stripe-pricing-table>
      </div>
   );
};
