import {useEffect, useState} from "react";

export const replaceTime = (date, time) => {
  let newDate = new Date(date).toString().split(' ');
  newDate[4] = new Date(time).toString().split(' ')[4];
  newDate = new Date(newDate.join(' '));
  return newDate;
};
export const prettyDateTime = time => {
  let date = new Date(time);
  return date.toLocaleDateString('en', {
    month: '2-digit',
    day: '2-digit',
    year: '2-digit',
  }) +
  ' at ' +
  date.toLocaleTimeString('en', { hour: '2-digit', minute: '2-digit' }).replace(/^0(?:0:0?)?/, '');
};
export const prettyDateTimeLineBreak = time => {
  let date = new Date(time);
  return <>
    {date.toLocaleDateString('en', {
      month: '2-digit',
      day: '2-digit',
      year: '2-digit',
    }) +
      <br /> +
      date.toLocaleTimeString('en', { hour: '2-digit', minute: '2-digit' }).replace(/^0(?:0:0?)?/, '')}
  </>;
};
export const dateToUnix = dateObj => {
  const date = new Date(dateObj);
  return Math.floor(date.getTime() / 1000);
};
export const minusMonth = (dateObj, num = 1) => {
  const date = new Date(dateObj);
  return prettyDate(date.setMonth(date.getMonth() - num));
};
export const plusMonth = (dateObj, num = 1) => {
  const date = new Date(dateObj);
  return prettyDate(date.setMonth(date.getMonth() + num));
};
export const prettyDate = time => new Date(time).toLocaleDateString('en', {});
export const prettyDateLong = time =>
  new Date(time).toLocaleDateString('en', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });
export const daysUntil = time => {
  const currentTime = new Date();
  const timeDifference = new Date(time) - currentTime;
  const oneDay = 1000 * 60 * 60 * 24;
  return Math.floor(timeDifference / oneDay);
};
export const prettyTime = time => {
  if (time.match(/^\d{4}-\d{2}-\d{2}T\d{2}$/)) {
    time += ':00';
  }
  return new Date(time).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'})
};
export const prettyLocalTime = (time) => {
  if (time.match(/^\d{4}-\d{2}-\d{2}T\d{2}$/)) {
    time += ':00';
  }
  const localDate = new Date(time);
  const timezoneOffsetInMilliseconds = localDate.getTimezoneOffset() * 60 * 1000;
  const localTimeInMilliseconds = localDate.getTime() - timezoneOffsetInMilliseconds;
  const localDateAdjusted = new Date(localTimeInMilliseconds);
  return localDateAdjusted.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
};


export const prettyHour = time =>
  new Date(time).toLocaleTimeString('en', { hour: '2-digit', minute: '2-digit' }).replace(/^0(?:0:0?)?/, '');
export const prettyPhone = number =>
  number.substr(2).replace(/(\d{1,2})(\d{1})?(\d{1,3})?(\d{1,4})?/, function (_, p1, p2, p3, p4) {
    let output = '';
    if (p1) output = `${p1}`;
    if (p2) output += `${p2}-`;
    if (p3) output += `${p3}-`;
    if (p4) output += `${p4}`;
    return output;
  });
export const toCleanString = sentence => (sentence.length ? sentence.toLowerCase().replace(/[^a-z0-9]/gi, '') : '');
export const toCleanEmailPrefix = sentence =>
  sentence.length ? sentence.toLowerCase().replace(/[^+a-z0-9]/gi, '') : '';
export const toCapitalize = sentence =>
  sentence.length ? sentence.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()) : '';
export const toCapitalizeFromUnderScore = (name = '') =>
  name
     .split(/_+/)
     .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
export const toUnderScoreSlug = name => {
  if (typeof name !== "string" || name.trim().length === 0) {
    return "";
  }
  return name
     .replace(/[^\w\s]/gi, '') // Remove any non-alphanumeric characters
     .split(' ')
     .map(word => word.toLowerCase())
     .join('_');
};


export const groupBy = (list, key) => {
  return list.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const keyBy = (array, key) => (array || []).reduce((r, x) => ({ ...r, [key ? x[key] : x]: x }), {});

export const keyByCollection = (collection, key) => {
  const c = collection || {};
  return c.isArray() ? keyBy(c, key) : Object.values(keyBy(c, key));
};
export const compactArray = array => array.filter(Boolean);

export const getCookie = n => {
  let a = `; ${document.cookie}`.match(`;\\s*${n}=([^;]+)`);
  return a ? a[1] : '';
};
export const setCookie = (name, value, days = 365) => {
  var expires = '';
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/';
};
export const eraseCookie = name => {
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
};

export const roundHalf = n => {
  return (Math.round(n * 2) / 2).toFixed(1);
};

export const cmToInFt = (cm, inches = Math.round(cm / 2.54)) => {
  return Math.floor(inches / 12) + "'" + (inches % 12) + '"';
};

export const kgToLbs = kg => {
  return Math.floor(kg * 2.20462262185);
};

export const truncate = (string, length = 20) => {
  return string.length > length ? string.substring(0, length) + '...' : string;
};

export const readFileAsString = file => {
  var request = new XMLHttpRequest();
  request.open('GET', file, false);
  request.send(null);
  var returnValue = request.responseText;
  return returnValue;
};
export const formatMoney = (amount, stripeMode) => {
  let number = stripeMode && amount ? String(amount).slice(0, -2) : amount;
  return Number(number).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
};

export const prettyNumber = (number, stripeMode) => {
  if (typeof number !== 'number') {
    return number;
  }
  return Intl.NumberFormat('en-US', {
    notation: 'compact',
    maximumFractionDigits: 1,
  }).format(number);
};

export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};
export const groupByConvo = arr => {
  const result = [];
  const seen = new Set();

  for (const item of arr) {
    const identifierValues = [
      item.responded_by || '',
      item.user_id || '',
      item.visitor_id || '',
      item.contact_id || '',
      item.from_phone_number || '',
      item.to_phone_number || '',
    ];
    const identifier = identifierValues.filter(Boolean).sort().join('');
    if (!seen.has(identifier)) {
      seen.add(identifier);
      result.push({
        identifier,
        messages: [],
      });
    }
  }

  for (const item of arr) {
    const identifierValues = [
      item.responded_by || '',
      item.user_id || '',
      item.visitor_id || '',
      item.contact_id || '',
      item.from_phone_number || '',
      item.to_phone_number || '',
    ];
    const identifier = identifierValues.filter(Boolean).sort().join('');
    const convo = result.find(x => x.identifier === identifier);
    convo.messages.push(item);
  }

  return result;
};

export const groupByConvo_LASTWORKING = arr => {
  const result = [];
  const seen = new Set();

  for (const item of arr) {
    const phoneNumbers = [item.from_phone_number, item.to_phone_number].sort().join(':');
    if (!seen.has(phoneNumbers)) {
      seen.add(phoneNumbers);
      result.push({
        phoneNumbers,
        messages: [],
      });
    }
  }

  for (const item of arr) {
    const phoneNumbers = [item.from_phone_number, item.to_phone_number].sort().join(':');
    const convo = result.find(x => x.phoneNumbers === phoneNumbers);
    convo.messages.push(item);
  }

  return result;
};

export const groupByConvo3 = (arr = []) => {
  let result = [];
  result = arr.reduce((r, a, index) => {
    r[a.from_phone_number + a.to_phone_number] = r[a.from_phone_number] || [];
    r[a.from_phone_number + a.to_phone_number].push(a);
    return r;
  }, new Object());

  return Object.entries(result);
};

export const groupByConvo2 = (arr = []) => {
  let result = [];
  result = arr.reduce((r, a, index) => {
    if (r[a.to_phone_number]) {
      r[a.to_phone_number].push(a);
    } else {
      r[a.from_phone_number] = r[a.from_phone_number] || [];
      r[a.from_phone_number].push(a);
    }
    return r;
  }, new Object());

  return Object.entries(result);
};

export const isValidEmail = input => {
  if (input.length == 0) return false;

  const emailRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
  return emailRegex.test(input);
};

export const determineROI = (
  total_email_usage,
  total_sms_usage,
  total_ai_text_usage,
  total_ai_image_usage,
  revenue,
) => {
  const monthlyUsageCost = calculateTotalUsageCost(
    total_email_usage,
    total_sms_usage,
    total_ai_text_usage,
    total_ai_image_usage,
  );
  return revenue - monthlyUsageCost;
};
export const determinePriceMargin = (
  total_email_usage,
  total_sms_usage,
  total_ai_text_usage,
  total_ai_image_usage,
  desiredRevenue,
) => {
  const monthlyUsageCost = calculateTotalUsageCost(
    total_email_usage,
    total_sms_usage,
    total_ai_text_usage,
    total_ai_image_usage,
  );
  return desiredRevenue - monthlyUsageCost;
};
export const determineRequiredPrice = (
  total_email_usage,
  total_sms_usage,
  total_ai_text_usage,
  total_ai_image_usage,
  targetMargin,
) => {
  const monthlyUsageCost = calculateTotalUsageCost(
    total_email_usage,
    total_sms_usage,
    total_ai_text_usage,
    total_ai_image_usage,
  );
  return monthlyUsageCost / (1 - targetMargin / 100);
};
export const determinePotentialTrialUsage = (
  total_email_usage,
  total_sms_usage,
  total_ai_text_usage,
  total_ai_image_usage,
  targetMargin,
  trialPeriod,
) => {
  const currentUsageCost = calculateTotalUsageCost(
    total_email_usage,
    total_sms_usage,
    total_ai_text_usage,
    total_ai_image_usage,
  );
  const targetPrice = determineRequiredPrice(
    total_email_usage,
    total_sms_usage,
    total_ai_text_usage,
    total_ai_image_usage,
    targetMargin,
  );
  const trialUsageCost = targetPrice * trialPeriod;
  return trialUsageCost - currentUsageCost; // if positive - can offer trial without harming profit margin
};
export const determinePricingStrategy = (trialPeriod = 14, targetMargin = 500, numPricePoints = 5) => {
  const pricePoints = [];

  for (let i = 0; i < numPricePoints; i++) {
    let price = (i + 1) * 10; // starting price at $10 and incrementing by $10
    let maxUsage = determineMaxUsage(price, targetMargin);
    let trialUsage = determinePotentialTrialUsage(
      maxUsage.maxEmailUsage,
      maxUsage.maxSMSUsage,
      maxUsage.maxAiTextUsage,
      maxUsage.maxAiPhotosUsage,
      targetMargin,
      trialPeriod,
    );

    // If trialUsage is greater than 1, increase price and recalculate maxUsage and trialUsage until trialUsage is less than 1
    while (trialUsage >= 1) {
      price++;
      maxUsage = determineMaxUsage(price, targetMargin);
      trialUsage = determinePotentialTrialUsage(
        maxUsage.maxEmailUsage,
        maxUsage.maxSMSUsage,
        maxUsage.maxAiTextUsage,
        maxUsage.maxAiPhotosUsage,
        targetMargin,
        trialPeriod,
      );
    }

    pricePoints.push({
      price,
      maxUsage: maxUsage,
      trialUsage: trialUsage,
    });
  }

  return pricePoints;
};
export const calculateTotalUsageCost = (
  total_email_usage,
  total_sms_usage,
  total_ai_text_usage,
  total_ai_image_usage,
) => {
  const emailRate = 0.0001;
  const smsRate = 0.007;
  const aiTextRate = 0.00002;
  const aiPhotoRate = 0.02;
  const smsMonthlyCharge = 1;

  const emailCost = total_email_usage * emailRate;
  const smsCost = total_sms_usage * smsRate + smsMonthlyCharge;
  const aiTextCost = total_ai_text_usage * aiTextRate;
  const aiPhotoCost = total_ai_image_usage * aiPhotoRate;

  return emailCost + smsCost + aiTextCost + aiPhotoCost; // total cost of usage
};
export const determineMaxUsage = (price, targetMargin) => {
  let maxEmailUsage = 0;
  let maxSMSUsage = 0;
  let maxAiTextUsage = 0;
  let maxAiPhotosUsage = 0;

  let usageCost = 0;
  let targetPrice = price / (1 - targetMargin / 100);

  while (usageCost < targetPrice) {
    maxEmailUsage += 1000;
    usageCost = calculateTotalUsageCost(maxEmailUsage, maxSMSUsage, maxAiTextUsage, maxAiPhotosUsage);
  }

  usageCost = 0;
  while (usageCost < targetPrice) {
    maxSMSUsage++;
    usageCost = calculateTotalUsageCost(maxEmailUsage, maxSMSUsage, maxAiTextUsage, maxAiPhotosUsage);
  }

  usageCost = 0;
  while (usageCost < targetPrice) {
    maxAiTextUsage += 1000;
    usageCost = calculateTotalUsageCost(maxEmailUsage, maxSMSUsage, maxAiTextUsage, maxAiPhotosUsage);
  }

  usageCost = 0;
  while (usageCost < targetPrice) {
    maxAiPhotosUsage++;
    usageCost = calculateTotalUsageCost(maxEmailUsage, maxSMSUsage, maxAiTextUsage, maxAiPhotosUsage);
  }

  return {
    maxEmailUsage,
    maxSMSUsage,
    maxAiTextUsage,
    maxAiPhotosUsage,
  };
};
export const determineUsagePricingStrategy = (desiredPricePoint, targetMargin = 500) => {
  const pricePoints = [];

  let price = desiredPricePoint; // starting price at desired price point
  let maxUsage = determineMaxUsage(price, targetMargin);
  let usageCost = calculateTotalUsageCost(
    maxUsage.maxEmailUsage,
    maxUsage.maxSMSUsage,
    maxUsage.maxAiTextUsage,
    maxUsage.maxAiPhotosUsage,
  );

  let alternativeUsages = [];
  let numEmails = maxUsage.maxEmailUsage;
  let numSMS = maxUsage.maxSMSUsage;
  let numAiText = maxUsage.maxAiTextUsage;
  let numAiPhotos = maxUsage.maxAiPhotosUsage;

  // Calculate alternative usage combinations with the same price
  while (usageCost <= price) {
    // Check if the usage cost is equal to the price
    if (usageCost === price) {
      alternativeUsages.push({
        numEmails: numEmails,
        numSMS: numSMS,
        numAiText: numAiText,
        numAiPhotos: numAiPhotos,
      });
    }

    // Decrement the usage of each type by 1000 until the usage cost is less than the price
    if (numEmails > 1000) {
      numEmails -= 1000;
    }
    if (numSMS > 1000) {
      numSMS -= 1000;
    }
    if (numAiText > 1000) {
      numAiText -= 1000;
    }
    if (numAiPhotos > 1000) {
      numAiPhotos -= 1000;
    }
    usageCost = calculateTotalUsageCost(numEmails, numSMS, numAiText, numAiPhotos);
  }

  pricePoints.push({
    price,
    maxUsage: maxUsage,
    usageCost: usageCost,
    alternativeUsages: alternativeUsages,
  });

  return pricePoints;
};
export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);
  
  return debouncedValue;
}
export const isValidCountryCode = (countryCode) => {
  // Check if countryCode is a valid country code
  // Since I can't check for all the country codes,
  // I'll assume any 1 to 4 digits code is valid for this example.
  return /^[0-9]{1,4}$/.test(countryCode);
};

export const extractCountryCode = (phoneNumber) => {
  return phoneNumber != null ? phoneNumber.slice(0, -10).trim().trimStart() : null;
};
export const removeCountryCode = (phoneNumber) => {
  if(!phoneNumber){
    return
  }
  let countryCode = extractCountryCode(phoneNumber);
  if(countryCode) {
    return phoneNumber.slice(countryCode.length).trim().trimStart();
  }
  return phoneNumber.trim().trimStart();
};
export const removeHttps = (url) => {
  return url != null ? url.replace(/(^\w+:|^)\/\//, '') : url;
}


