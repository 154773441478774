import axios from 'axios';
import { API_HOST } from '../constants/constants';
export const postUserLoginConfirm = async (loginData) => {
  const apiURL = API_HOST + '/auth/verify';
  
  try {
    const config = {
      headers: {
        'Cache-Control': 'no-store',
        'Content-Type': 'application/json',
        Accepts: 'application/json',
        'Access-Control-Max-Age': 60 * 60 * 24 //See: https://stackoverflow.com/a/40373949/609956
      },
    };
    const result = await axios.post(apiURL, loginData, config);
    return result;
  } catch (error) {
    console.error(error);
  }
};
