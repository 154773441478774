import axios from 'axios';
import { API_HOST } from '../constants/constants';
export const postExport = async (token, userID, type, format, listId) => {
  if(!type || !format){
    return false
  }
  const apiURL = API_HOST + '/export/' + type +'.' + format+'?listId='+listId;
  
  try {
    const result = await axios.post(apiURL,{
    },{
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+token,
        'x-sb-user-id': userID
      }
    });
    return result;
  } catch (error) {
    console.error(error);
  }
};
