import React from 'react';
import Header from '../../Shared/Header';
import BreadCums from '../../Shared/BreadCums';
import { RedocStandalone } from 'redoc';
import api from './api.json'
import Footer3 from "../../Shared/Footer3";

const APIDocs = () => {
   return (
        <>
            <Header
                parentMenu='pages'
                headerClass='sc-header-section inner-header-style'
                signUpBtn='inner-btn'
            />
            <BreadCums pageTitle='API Documentation' />
           <RedocStandalone
              spec={api}
              options={{
                 generateCodeSamples: {
                    languages: [
                       { lang: 'curl' },
                       { lang: 'Node.js' },
                       { lang: 'JavaScript', label: 'JS' },
                       { lang: 'TypeScript', label: 'TS' }],
                    skipOptionalParameters: true,
                 },
                 disableSearch: true,
                 disableSidebar: true,
                 hideDownloadButton:true,
                 nativeScrollbars: true,
                 showExtensions:true,
                 showObjectSchemaExamples:true,
                 expandResponses:'all',
                 jsonSampleExpandLevel:'all',
                 theme: { colors: { primary: { main: '#DD5191' } } },
              }}
              spec-Url='api.json'
              suppress-warnings='false'
              native-scrollbars='false'
              expand-responses='all'
              hide-hostname='false'
              scroll-y-offset='0'
              hide-loading='false'
              sort-properties-alphabetically='false'
              required-props-first='true'
              json-sample-expand-level='all'
              theme-color='#F64C72'
              hide-download-button='false'
              hide-single-request-sample-tab='false'
              show-extensions='true'
              allowed-unsafe-tags=''
           ></RedocStandalone>
           {/*<Home4Apps />*/}
           <Footer3/>
        </>
    );
};

export default APIDocs;
