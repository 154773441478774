import {createContext, useCallback, useEffect, useMemo, useState} from 'react';
import {useSnackbar} from "notistack";
import {postOrganizationFaq, updateOrganization, updateOrganizationFaq, updateUser} from "../api";
import useCurrentUser from "../hooks/useCurrentUser";
import useSummary from "../hooks/useSummary";
import {FAQ_LIMIT_LABEL, pricingData} from "../constants/constants";
import {useLocation, useNavigate} from "react-router-dom";
import {useMediaQuery, useTheme} from "@mui/material";
import {extractCountryCode, removeCountryCode, removeHttps} from "../helpers/helpers";
import useOrganizationFaqs from "../hooks/useOrganizationFaqs";

export const DemoContext = createContext({});
export const DemoContextProvider = ({ children, props }) => {
   const { enqueueSnackbar } = useSnackbar()
   const navigate = useNavigate();
   const production = useMemo(()=>window.location.hostname === 'savvygpt.co' || window.location.hostname === 'supportgpt.co',[]);
   const theme = useTheme();
   const matchSM = useMediaQuery(theme.breakpoints.down('sm'));
   const matchMD = useMediaQuery(theme.breakpoints.down('md'));
   
   const [chatOpen, setChatOpen] = useState(true); // used for demo purposes during customization
   const [convoMode, setConvoMode] = useState(false); // used for demo purposes during customization
   
   const [poweredBy, setPoweredBy] = useState(true);
   const [showTeam, setShowTeam] = useState(true);
   const [showFAQ, setShowFAQ] = useState(true);
   const [hideClose, setHideClose] = useState(false);
   const [buttonGradient, setButtonGradient] = useState(true);
   const [autoPrompt, setAutoPrompt] = useState(false);
   const [autoSlack, setAutoSlack] = useState(false);
   const [collectEmail, setCollectEmail] = useState(false);
   const [collectPhone, setCollectPhone] = useState(false);
   
   const [companyTheme, setCompanyTheme] = useState('auto');
   const [primaryColor, setPrimaryColor] = useState('#DD5191');
   const [secondaryColor, setSecondaryColor] = useState('#F1C660');
   const [tertiaryColor, setTertiaryColor] = useState('#9E9EA8');
   const [quaternaryColor, setQuaternaryColor] = useState('#F6F9FC');
   const [chatPosition, setChatPosition] = useState('right');
   const [companyAIName, setCompanyAIName] = useState('');
   const [companyAIPersonality, setCompanyAIPersonality] = useState('Professional, Formal, and Instructive');
   const [companyName, setCompanyName] = useState('');
   const [companyDesc, setCompanyDesc] = useState('');
   const [companyStatus, setCompanyStatus] = useState('');
   const [companyWebhook, setCompanyWebhook] = useState('');
   const [companyWebsite, setCompanyWebsite] = useState('');
   const [companyPhone, setCompanyPhone] = useState('');
   const [companyEmail, setCompanyEmail] = useState('');
   const [companyEmailNotifications, setCompanyEmailNotifications] = useState(false);
   const [companyGA, setCompanyGA] = useState('');
   const [companyCountryPhone, setCompanyCountryPhone] = useState('+1');
   const [companyType, setCompanyType] = useState('');
   const [previewMode, setPreviewMode] = useState('Closed');
   const [didSetup, setDidSetup] = useState(false);
   const [isLoadingOrganization, setIsLoadingOrganization] = useState(true);
   
   const darkMode =
      localStorage.getItem('THEME') === 'true' ||
      (!localStorage.getItem('THEME') &&
         window.matchMedia &&
         window.matchMedia('(prefers-color-scheme: dark)').matches);
   
   const user = useCurrentUser();
   const { token, userID, organizationID, isLoadingCurrentUser, isRefetchingCurrentUser,  plan: currentPlan } = user;
   //const user = useUser();
   //const isLoadingCurrentUser = useMemo(()=>user ? user.isLoadingCurrentUser : false, [user])
   //const isRefetchingCurrentUser = useMemo(()=>user ? user.isRefetchingCurrentUser : ()=>{}, [user])
   //const currentPlan = useMemo(()=>user && user.plan != undefined ? user.plan : 'Loading...', [user])
   const refetchCurrentUser = useMemo(()=>user ? user.refetchCurrentUser : ()=>{}, [user])
   const apiKey = useMemo(()=>user ? user.organizationID : '', [user])
   const organization = useMemo(()=>user ? user.organization : '', [user])
   const notificationSettings = useMemo(()=>user ? user?.user?.notification_settings : '', [user])
   const totalFAQs = useMemo(()=>user?.summary?.usage?.total_faq || 0, [user])
   
   const location = useLocation();
   const faqId = location.pathname.split("/").pop();
   
   useEffect(()=> {
      if(notificationSettings && (notificationSettings.includes('new_live_chat_recap') || notificationSettings.includes('widget_contact_created'))){
         setCompanyEmailNotifications(true)
      }
   },[notificationSettings])
   
   const isFeatureAllowed = useCallback((featureKey) => {
      if(!userID){
         return false
      }
      if (currentPlan) {
         const planFeatures = {
            free: ['feature1'],
            starter: ['feature1'],
            basic: ['auto_forward_to_slack', 'status_message', 'email_notifications'],
            growth: ['show_powered_by', 'capture_email', 'auto_forward_to_slack', 'status_message', 'email_notifications'],
            pro: ['show_powered_by', 'capture_email', 'capture_phone', 'auto_forward_to_slack', 'webhook_endpoint', 'status_message', 'email_notifications', 'api_docs'],
         };
         return planFeatures[currentPlan] && planFeatures[currentPlan].includes(featureKey);
      }
      return false;
   },[userID, currentPlan]);
   
   // Helper function to check if adding FAQ is allowed
   const isAddingFaqAllowed = useMemo(() => {
      if (currentPlan) {
         const plan = pricingData.find(item => item.plan.toLowerCase() === currentPlan);
         if (plan) {
            // If the plan has unlimited FAQs or the total FAQs is less than the plan allows, return true
            return plan.faq === "Unlimited" || totalFAQs < plan.faq;
         }
      }
      return false;
   }, [currentPlan, totalFAQs, pricingData, isLoadingCurrentUser && !isRefetchingCurrentUser]);
   
   
   const {
      isLoading: isLoadingFAQ,
      isRefetching: isRefetchingFAQ,
      data: faqs,
      refetch: refetchFaq,
   } = useOrganizationFaqs(apiKey);
   
   
   
   const selectedFAQ = useMemo(() => {
      if(!faqId !== undefined){
         return (
            faqs?.filter(
               item => item.id === faqId,
            )[0]
         );
      }
   }, [faqs, faqId]);
   
   const handleNewFAQ = useCallback(async (callback) =>{
      if (!isLoadingCurrentUser && !isRefetchingCurrentUser) {
      if (isAddingFaqAllowed) {
         const result = await postOrganizationFaq(token, userID, {question:"", status: 0});
         if (result && result.id) {
            refetchFaq().then(()=>{
               navigate(`../dashboard/faqs/${result.id}`)
            })
         }
      }else{
         enqueueSnackbar(FAQ_LIMIT_LABEL, {variant:'error'});
         return;
      }
      }
   },[refetchFaq, token, userID, isAddingFaqAllowed, isLoadingCurrentUser, isRefetchingCurrentUser, enqueueSnackbar])
   
   
   const {
      isLoading: isLoadingOrganizationSummary,
      isRefetching: isRefetchingOrganizationSummary,
      data: summary,
      refetch: refetchOrganizationSummary,
   } = useSummary();
   /*
   const {
      isLoading: isLoadingOrganization,
      data: organization,
      refetch: refetchOrganization,
   } = useOrganization();*/
   
   useEffect(()=>{
      if(organization && organization.id && !didSetup){
         setIsLoadingOrganization(false)
         //console.log('user ', user)
         //console.log('summary ', summary)
         //console.log('organization ',organization)
         setCompanyAIName(organization.ai_name)
         if(organization.ai_personality){
            setCompanyAIPersonality(organization.ai_personality)
         }
         if(organization.primary_color){
            setPrimaryColor(organization.primary_color)
         }
         if(organization.secondary_color){
            setSecondaryColor(organization.secondary_color)
         }
         if(organization.tertiary_color){
            setTertiaryColor(organization.tertiary_color)
         }
         if(organization.quaternary_color){
            setQuaternaryColor(organization.quaternary_color)
         }
         if(organization.show_powered_by){
            setPoweredBy(organization.show_powered_by)
         }
         if(organization.auto_forward_to_slack){
            setAutoSlack(organization.auto_forward_to_slack)
         }
         setCompanyEmail(organization.default_email)
         setCompanyName(organization.name)
         setCompanyDesc(organization.description)
         setCompanyWebsite(removeHttps(organization.website))
         setCompanyWebhook(removeHttps(organization.webhook_endpoint))
         setCompanyPhone(removeCountryCode(organization.phone_number))
         setCompanyCountryPhone(extractCountryCode(organization.phone_number))
         setCompanyStatus(organization.system_status)
         setDidSetup(true)
      }
   },[organization, didSetup])
   
   const handleSave = useCallback(()=>{
         updateOrganization(token, userID, organizationID, {
            name: companyName,
            default_email: companyEmail,
            website: (companyWebsite ? 'https://'+removeHttps(companyWebsite || '') : ''),
            webhook_endpoint: (companyWebhook ? 'https://'+removeHttps(companyWebhook || '') : ''),
            phone_number: companyCountryPhone+companyPhone,
            organization_type: companyType,
            description: companyDesc,
            google_analytics: companyGA,
            primary_color: primaryColor,
            secondary_color: secondaryColor,
            tertiary_color: tertiaryColor,
            quaternary_color: quaternaryColor,
            system_status: companyStatus,
            system_status_last_updated: companyStatus ? new Date() : null,
            ai_name: companyAIName,
            ai_personality: companyAIPersonality,
            auto_forward_to_slack: autoSlack
           // timezone: companyTimezone.offset,
          //  locale: companyLocale ? companyLocale.value : '',
         }).then(()=>{
            // success
            enqueueSnackbar('Saved!', {variant:'success'})
            refetchCurrentUser()
         })
   },[
      token, userID, organizationID,
      poweredBy,
      primaryColor,
      secondaryColor,
      tertiaryColor,
      quaternaryColor,
      companyAIName,
      companyAIPersonality,
      companyName,
      companyDesc,
      companyWebsite,
      companyPhone,
      companyEmail,
      companyGA,
      companyCountryPhone,
      companyType,
      companyStatus,
      companyWebhook,
      autoSlack,
      refetchCurrentUser
   ])
   const updateFAQ = useCallback((id, options) =>{
         updateOrganizationFaq(token, userID, id, options).then(()=>{
            // success
            enqueueSnackbar('Updated!', {variant:'success'})
         })
      
   },[token, userID])
   
   
   const handleNotificationSave = useCallback(() => {
      const commonNotifications = ['invite_sent','new_teammate','latest_features','monthly_report','weekly_report'];
      const additionalNotifications = !companyEmailNotifications ? ['new_live_chat_recap','widget_contact_created'] : [];
      const notification_settings = [...commonNotifications, ...additionalNotifications];
      updateUser(token, userID,{
         notification_settings: notification_settings,
      }).then(() => {
         enqueueSnackbar(`Notifications turned ${!companyEmailNotifications ? 'on' : 'off'}.`, {variant:'info'});
         setCompanyEmailNotifications(!companyEmailNotifications)
         //refetchUser();
      });
   }, [companyEmailNotifications, token, userID]);
   
   return (
    <DemoContext.Provider
      value={{
        actions: {
           refetchCurrentUser,
           handleNotificationSave,
           handleNewFAQ,
           isFeatureAllowed,
           isAddingFaqAllowed,
           setCompanyAIPersonality,
           setChatOpen,
           setConvoMode,
           setShowTeam,
           setShowFAQ,
           setHideClose,
           setButtonGradient,
           setAutoPrompt,
           setAutoSlack,
           setCollectEmail,
           setCollectPhone,
           setCompanyTheme,
           setPoweredBy,
           setPrimaryColor,
           setSecondaryColor,
           setTertiaryColor,
           setQuaternaryColor,
           setCompanyAIName,
           setCompanyName,
           setCompanyDesc,
           setCompanyWebsite,
           setCompanyPhone,
           setCompanyEmail,
           setCompanyEmailNotifications,
           setCompanyCountryPhone,
           setCompanyType,
           setPreviewMode,
           setCompanyStatus,
           setCompanyWebhook,
           setChatPosition,
           setCompanyGA,
           handleSave,
           updateFAQ,
           enqueueSnackbar,
           refetchFaq,
           refetchOrganizationSummary
        },
        state: {
           faqs,
           selectedFAQ,
           isLoadingFAQ,
           isRefetchingFAQ,
           isLoadingOrganizationSummary,
           isRefetchingOrganizationSummary,
           token, userID, organizationID,
           isLoadingCurrentUser,
           isRefetchingCurrentUser,
           isAddingFaqAllowed,
           matchSM,
           matchMD,
           previewMode,
           apiKey,
           production,
           summary,
           darkMode,
           poweredBy,
           primaryColor,
           secondaryColor,
           tertiaryColor,
           quaternaryColor,
           companyTheme,
           companyAIName,
           companyName,
           companyDesc,
           companyWebsite,
           companyPhone,
           companyEmail,
           companyEmailNotifications,
           companyCountryPhone,
           companyType,
           companyStatus,
           companyWebhook,
           chatPosition,
           companyAIPersonality,
           chatOpen,
           convoMode,
           isLoadingOrganization,
           showTeam,
           showFAQ,
           hideClose,
           buttonGradient,
           autoPrompt,
           autoSlack,
           collectEmail,
           collectPhone,
           companyGA,
           currentPlan,
        },
      }}
      {...props}
    >
      {children}
    </DemoContext.Provider>
  );
};
