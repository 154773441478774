import axios from 'axios';
import { API_HOST } from '../constants/constants';
export const getOrganizationActivity = async (token, userID) => {
  
  const apiURL = API_HOST + '/activity/all';
  try {
    const { data } = await axios.get(apiURL,{
      headers: {
        'Authorization': 'Bearer '+token,
        'x-gg-user-id': userID
      }
    });
    return data;
  } catch (error) {
    console.error(error);
  }
};
