import React from 'react';
import Header from '../../Shared/Header';
import BreadCums from '../../Shared/BreadCums';
import FaqQuestion from './FaqQuestion';
import Footer3 from '../../Shared/Footer3';
import Home4Apps from "../HomeFour/Home4Apps";

const Faq = () => {
    return (
        <>
            <Header
                parentMenu='pages'
                headerClass='sc-header-section inner-header-style'
                signUpBtn='inner-btn'
            />
            <BreadCums pageTitle='Faq' />
            <FaqQuestion />
           {/*<Home4Apps />*/}
            <Footer3 />
        </>
    );
};

export default Faq;
