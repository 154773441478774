import React, {useCallback, useMemo} from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import blogImg1 from '../../../assets/images/blog/blog.png'
import blogImg2 from '../../../assets/images/blog/blog_2.png'
import blogImg3 from '../../../assets/images/blog/blog_3.png';
import useBlogPosts from "../../../lib/hooks/useBlogPosts";
import {CircularProgress} from "@mui/material";
import {prettyDateLong, toUnderScoreSlug, truncate} from "../../../lib/helpers/helpers";

const BlogSidebar = ({search, setSearch}) => {
    const {data:blogPosts, isLoading} = useBlogPosts()
    const uniqueCategories = useMemo(() => blogPosts ? [...new Set(blogPosts.map(item => item.category))] : [], [blogPosts]);
    const uniqueTags = useMemo(() => blogPosts ? [...new Set(blogPosts.flatMap(item => item.tags.split(',')))] : [], [blogPosts]);
    
    const handleSidebarSearch = useCallback(event => {
        setSearch(event.target.value)
    },[])

    return (
        <>
            <div className="categories-area">
                <div className="cate_form sc-mb-35">
                    <h3 className="title sc-mb-23">Search</h3>
                    <form className="catergories-form">
                        <input type="text" id="Search" name="search" placeholder="Search... " onChange={handleSidebarSearch} />
                        <button type="submit" value="submit"><i className="ri-search-line"></i></button>
                    </form>
                </div>
                <div className="post-item sc-mb-35">
                    <h3 className="title sc-mb-20">Recent Post</h3>
    
                    {isLoading && <CircularProgress />}
                    {!isLoading && Boolean(blogPosts.length) && blogPosts.map(post=>{
                        return (
                           <div key={`sp_${post.id}`} className="recent-content d-flex align-items-center sc-mb-13">
                               <div className="recent-image" >
                                   <img src={post.image} alt={post.title} style={{borderRadius:'25%', maxWidth:110, maxHeight:110}} />
                               </div>
                               <div className="recent-text sc-pl-20">
                                   <h5>
                                       <Link to={`../../blog/${toUnderScoreSlug(post.title)}`}>{truncate(post.title,50)}</Link>
                                   </h5>
                                   <div className="calender-item">
                                       <i className="ri-calendar-fill"></i>
                                       <span>{prettyDateLong(post.created_at)}</span>
                                   </div>
                               </div>
                           </div>)
                    })}
                </div>
                <div className="categories-item sc-mb-25">
                    <h3 className="title sc-mb-15">Category</h3>
                    <ul className="list">
                        {isLoading && <CircularProgress />}
                        {!isLoading && Boolean(uniqueCategories.length) && uniqueCategories.map((category,index)=> {
                               return (
                                  <li key={`c_${index}`}><Link onClick={()=>setSearch(category)} to={`../../blog/#${toUnderScoreSlug(category)}`}>{category}</Link></li>)
                           })}
                    </ul>
                </div>
                <div className="categories-tag">
                    <h3 className="title sc-mb-15">Tag</h3>
                    <ul className="list">
                        {isLoading && <CircularProgress />}
                        {!isLoading && Boolean(uniqueTags.length) && uniqueTags.map((tag,index)=> {
                            return <li key={`t_${index}`}><Link key={index} onClick={()=>setSearch(tag)} to={`../../blog/#${toUnderScoreSlug(tag)}`}>{tag}</Link></li>
                        })}
                    </ul>
                </div>
            </div>
        </>
    );
};

export default BlogSidebar;
