import React, {useContext, useMemo} from 'react';
import {Button, Card, CardContent, CircularProgress, Grid, Tooltip, Typography} from "@mui/material";
import {Info} from "@mui/icons-material";
import {prettyNumber, toCapitalizeFromUnderScore} from "../../../lib/helpers/helpers";
import {DemoContext} from "../../../lib/contexts/DemoContext";
import {Link} from "react-router-dom";
const PlanDetails = () => {
   
   const {
      state: { isLoading, production, currentPlan, summary, matchMD, matchSM, isLoadingCurrentUser, isRefetchingCurrentUser },
   } = useContext(DemoContext);
   const usage = useMemo(()=>summary?.usage || [], [summary])
   //const analytics = useMemo(()=>summary?.analytics || [], [summary])
   //const totalResponses = useMemo(()=>analytics.replied?.reduce((a, b) => a + b, 0) || 0, [analytics])
   const { total_faq, total_allowed_faq, total_responses, total_allowed_responses } = usage || ''

   return (
        <>
           <Card style={{maxHeight:matchSM ? 'auto' : 260, height:matchSM ? 'auto' : 260}}>
              <CardContent>
                 <Grid container item xs={12} spacing={matchMD ? 1 : 4} style={{margin:'auto', marginLeft:-16}}>
                    <Grid item xs={matchSM ? 6 : matchMD ? 12 : 6}>
                       <Typography variant={'body1'}>Current Plan</Typography>
                       <Typography variant={'body2'} color={'primary'}>{(isLoadingCurrentUser || isRefetchingCurrentUser) && currentPlan != undefined ? <CircularProgress size={20} /> :
                          <>{toCapitalizeFromUnderScore(currentPlan)}</>}</Typography>
                    </Grid>
                    <Grid item xs={matchSM ? 6 : matchMD ? 12 : 6} alignContent={'center'} alignItems={'center'} justifyContent={'center'} container>
                       <Link target={'_blank'} to={production ? "https://billing.stripe.com/p/login/3cs7tK3Cx1Md2mQ8ww" : "https://billing.stripe.com/p/login/test_6oEbJsfht5265La4gg"}>
                          <Button variant={'outlined'} color={'primary'}>Manage</Button>
                       </Link>
                    </Grid>
                 </Grid>
                 <hr/>
                 <Grid container item xs={12} spacing={matchMD ? 1 : 3} style={{margin:'auto', marginLeft:-12}}>
                    <Grid item xs={matchSM ? 6 : matchMD ? 12 : 6}>
                       <Typography variant={'body1'}>Total FAQ</Typography>
                    </Grid>
                    <Grid item xs={matchSM ? 6 : matchMD ? 12 : 6}>
                       {isLoading ? <CircularProgress size={'small'}/> : <Typography variant={'body2'} color={'textSecondary'} style={{color: total_faq !=0 && total_faq == total_allowed_faq ? '#DA4045' : 'inherit'}}>{prettyNumber(total_faq)} / {prettyNumber(total_allowed_faq)}</Typography>}
                    </Grid>
                    <Grid item xs={matchSM ? 6 : matchMD ? 12 : 6}>
                       <Typography variant={'body1'}>Total Responses</Typography>
                    </Grid>
                    <Grid item xs={matchSM ? 6 : matchMD ? 12 : 6}>
                       {isLoading ? <CircularProgress size={'small'}/> : <Typography variant={'body2'}  color={'textSecondary'} style={{color: total_responses !=0 && total_responses == total_allowed_responses ? '#DA4045' : 'inherit'}}>{prettyNumber(total_responses)} / {prettyNumber(total_allowed_responses)} <Tooltip title={'Resets monthly on billing date'}><Info style={{fontSize:16}}/></Tooltip></Typography>}
                    </Grid>
                    {isLoadingCurrentUser || isRefetchingCurrentUser ? <CircularProgress size={20} /> :
                    <>
                       {total_responses != 0 &&
                          <Grid item xs={12}>
                             <Typography
                                variant={'body2'}
                                style={{
                                   color: total_responses / total_allowed_responses >= 0.9
                                      ? '#f8ab3a'
                                      : total_responses == total_allowed_responses
                                         ? '#DA4045'
                                         : 'inherit'
                                }}
                             >
                                {total_responses / total_allowed_responses >= 0.9
                                   ? "Heads up! You are within 10% of your limit. Upgrade now to prevent interruption."
                                   : total_responses == total_allowed_responses
                                      ? "You're popular! You've reached this month's limit, upgrade now to resume responses."
                                      : ""
                                }
                             </Typography>
                          </Grid>
                       }
                    </>
                    }

                 </Grid>
              </CardContent>
           </Card>
        </>
    );
};

export default PlanDetails;
