import React, {useContext, useMemo, useState} from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import logo from '../../assets/images/savvy_logo.png'
import logoDark from '../../assets/images/savvy_logo.png';
import OffCanvasMenu from './OffCanvasMenu';
import MenuItems from './MenuItems';
import {UserContext} from "../../lib/contexts";
import {useMediaQuery, useTheme} from "@mui/material";

const Header = (props) => {
    const { parentMenu, headerClass, headerNormalLogo, headerStickyLogo, signUpBtn } = props;
    
    const theme = useTheme();
    const matchSM = useMediaQuery(theme.breakpoints.down('sm'));
    const matchMD = useMediaQuery(theme.breakpoints.down('md'));
    const {
        state: {
            user_id
        },
        actions: {
            logout,
        },
    } = useContext(UserContext);

    const [stickyHeader, setStickyHeader] = useState(false)
    let sticky = () => {
        if (window.scrollY > 150) {
            setStickyHeader(true)
        } else {
            setStickyHeader(false)
        }
    }
    window.addEventListener("scroll", sticky)
    const production = useMemo(()=>window.location.hostname === 'savvygpt.co',[]);
    return (
        <>
            <header className={user_id || stickyHeader ? headerClass + " sc-header-sticky" : headerClass} id="sc-header-sticky">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between">
                        <div className={user_id ? "col-lg-4 col-md-4 col-sm-3" : "col-lg-4 col-md-2 col-sm-2"}>
                            <div className="sc-header-left-wraper d-flex align-items-center">
                                <div className="sc-main-logo sc-mr-60 sc-md-mr-20 text-center text-sm-start">
                                    <div className="default-logo logo-area">
                                        <Link to="/#">
                                            <img className="d-none d-md-block" src={headerNormalLogo ? headerNormalLogo : logo} style={{maxWidth:matchSM ? 200 : matchMD ? 225 : 350, width: matchSM ? 200 : matchMD ? 225 : 350}} alt="logo" />
                                            <img className="d-block d-md-none" src={headerNormalLogo ? headerNormalLogo : logoDark} style={{maxWidth:matchSM ? 200 : matchMD ? 225 : 350, width: matchSM ? 200 : matchMD ? 225 : 350}} alt="logo" />
                                        </Link>
                                    </div>
                                    <div className="sticky-logo logo-area">
                                        <Link to="/#">
                                            <img src={headerStickyLogo ? headerStickyLogo : logo} style={{maxWidth:matchSM ? 200 : matchMD ? 225 : 350, width: matchSM ? 200 : matchMD ? 225 : 350}} alt="logo" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {matchSM && <div className="header-list d-sm-none d-block">
                            <ul className='d-inline-flex d-block d-sm-none position-fixed' style={{right: 20, top: 20}}>
                                <li className="canvas text-white">
                                    <OffCanvasMenu></OffCanvasMenu>
                                </li>
                            </ul>
                        </div>}
                        {/* OffCanvas Area  */}
                        <div className={user_id ? "col-lg-8 col-md-8 col-sm-10 d-sm-block d-none" : "col-lg-8 col-md-10 col-sm-10 d-sm-block d-none"}>
                            
                            <div className="header-btn-area p-z-idex d-flex align-items-center justify-content-center justify-content-sm-end">
                                
                                <div className={"header-list sc-sm-pt-30 sc-sm-pb-30 sc-md-pt-30 sc-md-pb-30 sc-pt-40 sc-pb-40"}>
                                    <ul className='d-flex'>
                                        <MenuItems
                                           parentMenu={parentMenu}
                                        />
                                        <li>
                                            <Link to={user_id ? "#" : "../../login"} onClick={user_id ? ()=>logout() : ()=>{}}>{
                                               !user_id && <i className="ri-lock-line"></i>}{user_id ? "Logout" : "Login"} </Link>
                                        </li>
                                    </ul>
                                </div>
                                {!user_id && <div className="header-btn2 sc-pl-30 sc-sm-pl-20 d-none d-lg-block">
                                    <Link className={signUpBtn ? signUpBtn : "sign-in-btn"} to="../../pricing#"><span>Get Started</span></Link>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
}

export default Header;
