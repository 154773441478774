import React, { useState } from 'react';
import { Fade } from '@mui/material';
import ModalVideo from 'react-modal-video';
import { HashLink as Link } from 'react-router-hash-link';
import banner12 from '../../../assets/images/banner/banner-12.png'
import perspective from '../../../assets/images/icons/perspective.png'
import bannerShape from '../../../assets/images/icons/banner_shape.png'
import sectionBg from '../../../assets/images/banner/section-bg.png'
import sectionBg1 from '../../../assets/images/banner/section-bg1.png'
import sectionBg2 from '../../../assets/images/banner/section-bg2.png'
import sectionBg3 from '../../../assets/images/banner/section-bg3.png'
import mediaImage3 from "../../../assets/images/feature/media-image3.png";
import {useMediaQuery, useTheme} from "@mui/material";

const Home4Banner = () => {
    const [isOpen, setOpen] = useState(false)
    const theme = useTheme();
    const matchSM = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <>
            <section className="section-bg-shape">
                <div className="sc-banner-section-area7 p-z-idex sc-pt-160 sc-pb-90 sc-md-pb-80">
                    <div className="container-fluid">
                        <div className="row sc-banner-item d-flex align-items-center">
                            <div className={matchSM ? "col-lg-12" : "col-lg-6"}>
                                <div className={`banner-header-content ${matchSM ? 'sc-pl-10' : 'sc-pl-95'}`}>
                                    <Fade in={true} timeout={1000}>
                                        <div>
                                        <div className="sc-banner-title sc-mb-25">
                                            <h1 className="banner-title sc-mb-0 p-z-idex">
                                                AI-Powered <span className="gradiant-color">Live Chat</span>
                                               {/* <img src={perspective} alt="Icon" />*/}
                                            </h1>
                                            <img src={bannerShape} alt="Images" />
                                        </div>
                                        <div className="sc-mb-40">Discover the limitless capabilities of <span className="gradiant-color">SavvyGPT</span>, the infinitely-scalable, AI-powered Employee, Live Chat, Knowledge Base, and Customer Solution.</div>
                                        </div>
                                    </Fade>
                                    <div className="banner-video-area d-flex align-items-center d-none">
                                        <Link className="gradiant-btn banner-button sc-mr-25" to="/signup#">
                                            <span>Get Started</span>
                                        </Link>
                                        <Link className="popup-video"  to="/faq">
                                            <span className='ms-2'>How it Works</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className={matchSM ? "col-lg-12" : "col-lg-6"}>
                                <div className="banner_image shake-y"><img src={mediaImage3} alt="Images" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sc-section-bg">
                    <img className="bg-image1" src={sectionBg} alt="Bg" />
                    <img className="bg-image2" src={sectionBg1} alt="Bg" />
                    <img className="bg-image3" src={sectionBg2} alt="Bg" />
                    <img className="bg-image4" src={sectionBg3} alt="Bg" />
                </div>
            </section>
        </>
    );
};

export default Home4Banner;
