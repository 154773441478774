import axios from 'axios';
import useCurrentUser from '../hooks/useCurrentUser';
import { API_HOST } from '../constants/constants';
export const getActivity = async (contactId, type = 'contact') => {
  if(!contactId){
    return false
  }
  const apiURL = API_HOST + ('/activity/'+(type)+'/'+contactId);
  const { token, userID } = useCurrentUser();
  try {
    const { data } = await axios.get(apiURL,{
      headers: {
        'Authorization': 'Bearer '+token,
        'x-sb-user-id': userID
      }
    });
    return data;
  } catch (error) {
    console.error(error);
  }
};
