import { useQuery } from 'react-query';
import { getContacts } from '../api/getContacts';
import useCurrentUser from "./useCurrentUser";

const useContacts = (listId, exclude, contactId = null) => {
  const { token, userID } = useCurrentUser();
  const result = useQuery('contacts_'+((listId || contactId) ?? 'all')+exclude, () => getContacts(listId,exclude, contactId, token, userID), {
    refetchOnWindowFocus: false,
    staleTime: 5000,
  });
  return result;
};

export default useContacts;
