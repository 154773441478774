import React from 'react';
const TermsDetails = () => {
    return (
        <>
            <section className="sc-feature-section-area5 sc-pt-175 sc-md-pt-115 sc-pb-140 sc-md-pb-70">
                <div className="container">
                    <div className="row align-items-center">
                       Terms of Service - SavvyGPT<br/><br/>
                       Last Effective Date: June 3, 2023<br/><br/>
                       Welcome to SavvyGPT, a product of Formulate Labs, Inc. These Terms of Service ("Agreement") govern your use of the SavvyGPT service and website (collectively referred to as the "Service"). By accessing or using the Service, you agree to be bound by these Terms of Service. If you do not agree with any provision of these terms, please do not access or use the Service.
                       <br/><br/>
                       Acceptance of Terms<br/><br/>
                       By accessing or using the Service, you represent and warrant that you have read, understood, and agree to be bound by this Agreement and any additional terms and conditions applicable to specific features of the Service.
                       <br/><br/>
                       Service Description<br/><br/>
                       SavvyGPT is an AI-powered Live Chat, Knowledge Base, and Customer Solution platform offered by Formulate Labs, Inc. The Service provides businesses with AI-driven customer support solutions, including instant live chat responses, a comprehensive knowledge base, auto-translation capabilities, and customizable features.
                       <br/><br/>
                       Use of the Service<br/><br/>
                       3.1. Eligibility: You must be at least 18 years old or the legal age of majority in your jurisdiction to use the Service. By accessing or using the Service, you represent and warrant that you meet the eligibility requirements.
                       <br/><br/>
                       3.2. User Accounts: In order to use certain features of the Service, you may be required to create a user account. You are responsible for maintaining the confidentiality of your account credentials and for any activities or actions taken under your account. You agree to notify us immediately of any unauthorized use of your account.
                       <br/><br/>
                       Intellectual Property<br/><br/>
                       4.1. Ownership: The Service, including its logo, images, brand elements, and all associated intellectual property rights, are owned by Formulate Labs, Inc. You acknowledge that all trademarks, logos, and service marks displayed on the Service are the property of Formulate Labs or their respective owners.
                       <br/><br/>
                       4.2. License: Formulate Labs grants you a limited, non-exclusive, non-transferable, and revocable license to access and use the Service for its intended purpose. This license does not grant you any rights to use the trademarks, logos, or other proprietary marks of Formulate Labs without prior written consent.
                       <br/><br/>
                       Data Privacy<br/><br/>
                       5.1. Privacy Policy: Your privacy is important to us. Please refer to our Privacy Policy, which explains how we collect, use, and disclose your personal data in connection with the Service.
                       <br/><br/>
                       Limitation of Liability<br/><br/>
                       6.1. Disclaimer: The Service is provided on an "as is" and "as available" basis. Formulate Labs does not warrant or guarantee the accuracy, completeness, reliability, or availability of the Service. You use the Service at your own risk.
                       <br/><br/>
                       6.2. Limitation of Liability: To the extent permitted by applicable law, Formulate Labs shall not be liable for any indirect, incidental, consequential, special, or exemplary damages arising out of or in connection with your use of the Service.
                       <br/><br/>
                       Modification and Termination<br/><br/>
                       7.1. Modification: Formulate Labs reserves the right to modify, suspend, or discontinue the Service, or any part thereof, at any time without prior notice.
                       <br/><br/>
                       7.2. Termination: Formulate Labs may terminate or suspend your access to the Service immediately, without prior notice or liability, for any reason whatsoever, including, without limitation, if you breach this Agreement.
                       <br/><br/>
                       Governing Law and Dispute Resolution<br/><br/>
                       8.1. Governing Law: This Agreement shall be governed by and construed in accordance with the laws of Florida.
                       <br/><br/>
                       8.2. Dispute Resolution: Any disputes arising out of or in connection with this Agreement shall be subject to the exclusive jurisdiction of the courts of Florida.
                       <br/><br/>
                       Intellectual Property Ownership<br/><br/>
                       9.1. Intellectual Property Ownership: All intellectual property rights associated with the Service, including any improvements, modifications, or derivatives thereof, belong exclusively to Formulate Labs, Inc.
                       <br/><br/>
                       User Responsibilities<br/><br/>
                       10.1. User Responsibilities: You agree to use the Service in compliance with applicable laws and regulations and refrain from using the Service in a manner that violates the rights of others or interferes with the proper functioning of the Service.
                       <br/><br/>
                       Indemnification<br/><br/>
                       11.1. Indemnification: You agree to indemnify and hold harmless Formulate Labs, its affiliates, and their respective directors, officers, employees, and agents from any claims, damages, liabilities, or expenses arising out of or related to your use of the Service.
                       <br/><br/>
                       Data Retention<br/><br/>
                       12.1. Data Retention: User data will be retained for the duration necessary to fulfill the purposes outlined in the Privacy Policy. The criteria used to determine the retention period will be based on the nature of the data and the legal requirements.
                       <br/><br/>
                       Data Security Measures<br/><br/>
                       13.1. Data Security Measures: Formulate Labs implements reasonable security measures to protect user data, including encryption, access controls, and regular security audits.
                       <br/><br/>
                       Data Transfer and Storage<br/><br/>
                       14.1. Data Transfer and Storage: If user data is transferred or stored outside of the user's jurisdiction, Formulate Labs ensures adequate protection for the transferred data, such as through the use of standard contractual clauses or compliance with relevant data protection laws.
                       <br/><br/>
                       Third-Party Services<br/><br/>
                       15.1. Third-Party Services: If the Service integrates with third-party services or platforms, user data may be shared with or accessed by these third parties. Formulate Labs takes responsibility for protecting user data when shared with third parties.
                       <br/><br/>
                       Data Breach Notification<br/><br/>
                       16.1. Data Breach Notification: In the event of a data breach, Formulate Labs will follow a procedure to notify affected users and relevant authorities within the timeframe required by applicable data breach notification laws.
                       <br/><br/>
                       Age Requirements<br/><br/>
                       17.1. Age Requirements: The Service is not intended for use by individuals under the age of 18. Formulate Labs does not knowingly collect personal information from children. If you believe that personal information has been collected from a child without proper consent, please contact us.
                       <br/><br/>
                       Modifications to the Terms of Service<br/><br/>
                       18.1. Modifications: Formulate Labs reserves the right to modify or update these Terms of Service at any time. Any changes will be effective immediately upon posting the revised version on the Service. Continued use of the Service after any changes constitutes your acceptance of the modified Terms of Service.
                       <br/><br/>
                       Third-Party Links and Content<br/><br/>
                       19.1. Third-Party Links: The Service may contain links to third-party websites or services that are not owned or controlled by Formulate Labs. Formulate Labs does not endorse or assume any responsibility for the content, privacy policies, or practices of any third-party websites or services.
                       <br/><br/>
                       Dispute Resolution<br/><br/>
                       20.1. Dispute Resolution: Any disputes arising out of or in connection with this Agreement shall be resolved through binding arbitration in accordance with the rules of the arbitration association designated by Formulate Labs.
                       <br/><br/>
                       Waiver of Class Action<br/><br/>
                       21.1. Waiver of Class Action: Any disputes between you and Formulate Labs shall be brought solely on an individual basis and not as a plaintiff or class member in any class or representative action.
                       <br/><br/>
                       Termination for Breach<br/><br/>
                       22.1. Termination for Breach: Formulate Labs reserves the right to terminate or suspend your access to the Service immediately, without prior notice or liability, in the event of a breach of these Terms of Service.
                       <br/><br/>
                       Governing Law for Users Outside the United States<br/><br/>
                       23.1. Governing Law for Users Outside the United States: If you access or use the Service from outside the United States, you agree that any disputes arising out of or in connection with this Agreement shall be governed by and construed in accordance with the laws of the jurisdiction where you reside.
                       <br/><br/>
                       Entire Agreement<br/><br/>
                       24.1. Entire Agreement: This Agreement, including any additional terms and conditions applicable to specific features of the Service, constitutes the entire agreement between you and Formulate Labs and supersedes any prior or contemporaneous agreements, understandings, or representations, whether oral or written.
                       <br/><br/>
                       No Agency<br/><br/>
                       25.1. No Agency: Nothing in this Agreement shall be construed as creating a partnership, joint venture, agency, or employment relationship between you and Formulate Labs.
                    </div>
                    </div>
            </section>
        </>
    );
};

export default TermsDetails;
