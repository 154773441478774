import React, {useContext, useState} from 'react';
import { useLocation } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import logoDark from '../../assets/images/savvy_logo.png';
import {DemoContext} from "../../lib/contexts/DemoContext";
import {UserContext} from "../../lib/contexts";

const OffCanvasMenu = (props) => {
    const [iconToggle1, setIconToggle1] = useState(true)
    const [iconToggle2, setIconToggle2] = useState(true)
    const [iconToggle3, setIconToggle3] = useState(true)

    const { parentMenu } = props;
    const location = useLocation();
    
    const {
        state: {
            user_id
        },
    } = useContext(UserContext);
    const {
        actions: {
            isFeatureAllowed,
            logout
        },
    } = useContext(DemoContext);
    return (
        <>
            <div className='offcanvas-area'>
                <Link data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"><i className="ri-menu-line"></i></Link>
                <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                    <div className="offcanvas-header">
                        <Link to="../../"><img src={logoDark} alt="DarkLogo" /></Link>&nbsp;
                        <button type="button" className="offcanvasClose" data-bs-dismiss="offcanvas" aria-label="Close"><i className="ri-close-fill"></i></button>
                    </div>
                    <div className="offcanvas-body">
                        <nav className="navbar navbar-light">
                            <div className="w-100">
                                <div className="collapse navbar-collapse show" id="navbarSupportedContent">
                                    {/*<ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                        <li className="nav-item dropdown">
                                            <Link className={parentMenu === 'pages' ? 'nav-link dropdown-toggle active' : 'nav-link dropdown-toggle'} to="/#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" onClick={() => setIconToggle1(!iconToggle1)}>
                                                Home
                                                {iconToggle1 ? <i className="ri-add-line"></i> : <i className="ri-subtract-line"></i>}
                                            </Link>
                                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                <li>
                                                    <Link className={location.pathname === '/' ? 'dropdown-item active' : 'dropdown-item'} to="/#">Home One</Link>
                                                </li>
                                                <li>
                                                    <Link className={location.pathname === '/home2' ? 'dropdown-item active' : 'dropdown-item'} to="/home2#">Home Two</Link>
                                                </li>
                                                <li>
                                                    <Link className={location.pathname === '/home3' ? 'dropdown-item active' : 'dropdown-item'} to="/home3#">Home Three</Link>
                                                </li>
                                                <li>
                                                    <Link className={location.pathname === '/home4' ? 'dropdown-item active' : 'dropdown-item'} to="/home4#">Home Four</Link>
                                                </li>
                                                <li>
                                                    <Link className={location.pathname === '/home5' ? 'dropdown-item active' : 'dropdown-item'} to="/home5#">Home Five</Link>
                                                </li>
                                                <li>
                                                    <Link className={location.pathname === '/home6' ? 'dropdown-item active' : 'dropdown-item'} to="/home6#">Home Six</Link>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className="nav-item">
                                            <Link className={location.pathname === '/about' ? 'nav-link active' : 'nav-link'} to="/about#">About Us</Link>
                                        </li>
                                        <li className="nav-item dropdown ">
                                            <Link className={parentMenu === 'blog' ? 'nav-link dropdown-toggle active' : 'nav-link dropdown-toggle'} to="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" onClick={() => setIconToggle3(!iconToggle3)}>
                                                Blog
                                                {iconToggle3 ? <i className="ri-add-line"></i> : <i className="ri-subtract-line"></i>}
                                            </Link>
                                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                <li><Link className={location.pathname === '/blog' ? 'dropdown-item active' : 'dropdown-item'} to="/blog#">Blog</Link></li>
                                                <li><Link className={location.pathname === '/blog-details' ? 'dropdown-item active' : 'dropdown-item'} to="/blog-details#">Blog Details</Link></li>
                                            </ul>
                                        </li>
                                        <li className="nav-item">
                                            <Link className={location.pathname === '/contact' ? 'nav-link active' : 'nav-link'} to="/contact#">Contact</Link>
                                        </li>
                                    </ul>*/}
                                    {!user_id && <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                        <li className="nav-item">
                                            <Link className={location.pathname === '/' ? 'nav-link active' : 'nav-link'}
                                                  to="../../">Features</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link
                                               className={location.pathname === '/dashboard/faqs' ? 'nav-link active' : 'nav-link'}
                                               to="../../pricing">Pricing</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link
                                               className={location.pathname === '/dashboard/customize' ? 'nav-link active' : 'nav-link'}
                                               to="../../faq">FAQs</Link>
                                        </li>
        
                                        {(isFeatureAllowed('capture_email') || isFeatureAllowed('capture_phone')) &&
                                           <li className="nav-item">
                                               <Link
                                                  className={location.pathname === '/dashboard/contacts' ? 'nav-link active' : 'nav-link'}
                                                  to="../../dashboard/contacts">Contacts</Link>
                                           </li>}
                                    </ul>}
                                    {user_id && <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                        <li className="nav-item">
                                            <Link className={location.pathname === '/' ? 'nav-link active' : 'nav-link'}
                                                  to="../../dashboard">Dashboard</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link
                                               className={location.pathname === '/dashboard/faqs' ? 'nav-link active' : 'nav-link'}
                                               to="../../dashboard/faqs">Manage FAQs</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link
                                               className={location.pathname === '/dashboard/customize' ? 'nav-link active' : 'nav-link'}
                                               to="../../dashboard/customize">Customize</Link>
                                        </li>
        
                                        {(isFeatureAllowed('capture_email') || isFeatureAllowed('capture_phone')) &&
                                           <li className="nav-item">
                                               <Link
                                                  className={location.pathname === '/dashboard/contacts' ? 'nav-link active' : 'nav-link'}
                                                  to="../../dashboard/contacts">Contacts</Link>
                                           </li>}
    
                                        <li className="nav-item">
                                            <Link
                                               className={location.pathname === '/dashboard/customize' ? 'nav-link active' : 'nav-link'}
                                               to="#" onClick={user_id ? ()=>logout() : ()=>{}}>Logout</Link>
                                        </li>
                                    </ul>}
                                </div>
                            </div>
                        </nav>
                        <div className="offcanvas-icon-list d-none">
                            <ul>
                                <li>
                                    <a href="https://www.facebook.com/FormulateLabs"><i className="ri-facebook-fill"></i></a>
                                </li>
                                <li>
                                    <a href="https://www.twitter.com/Support_GPT"><i className="ri-twitter-fill"></i></a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/FormulateLabs"><i className="ri-video-fill"></i></a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/FormulateLabs"><i className="ri-linkedin-fill"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OffCanvasMenu;
