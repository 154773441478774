import React from 'react';
const PrivacyDetails = () => {
    return (
        <>
            <section className="sc-feature-section-area5 sc-pt-175 sc-md-pt-115 sc-pb-140 sc-md-pb-70">
                <div className="container">
                    <div className="row align-items-center">
                        Privacy Policy - SavvyGPT<br/><br/>
                        Last Effective Date: June 3, 2023<br/><br/>
                        This Privacy Policy describes how SavvyGPT, a product of Formulate Labs, Inc ("we," "us," or "our") collects, uses, and protects the personal data you provide while accessing or using the SavvyGPT service (the "Service"). By using the Service, you consent to the practices described in this Privacy Policy.
                        <br/><br/>
                        1. Information We Collect
                        <br/><br/>
                        1.1. Personal Information: When you sign up for an account or use certain features of the Service, we may collect personal information that can identify you, such as your name, email address, contact details, and login credentials. We only collect personal information that is necessary for the purposes outlined in this Privacy Policy.
                        <br/><br/>
                        1.2. Usage Data: We may collect non-personal information about your interactions with the Service, such as the pages you visit, the features you use, and the actions you take. This information is collected to improve the functionality and performance of the Service and for analytics purposes.
                        <br/><br/>
                        2. Use of Information
                        <br/><br/>
                        2.1. Providing and Improving the Service: We use the information we collect to operate, maintain, and improve the Service, including personalizing the user experience, responding to your inquiries, providing technical support, and offering additional integrations.
                        <br/><br/>
                        2.2. Communication: We may use your email address or other contact information to send you administrative notifications, updates, marketing communications, and information about the Service. You can opt out of receiving such communications by following the instructions provided in the communication or by contacting us directly.
                        <br/><br/>
                        2.3. Aggregated Data: We may aggregate and anonymize the data collected from users to generate statistical and analytical insights. This aggregated data does not identify any individual user and may be used for research, marketing, or other business purposes.
                        <br/><br/>
                        3. Data Sharing and Disclosure
                        <br/><br/>
                        3.1. Service Providers: We may engage third-party service providers to perform certain functions on our behalf, such as hosting the Service, processing payments, analyzing user data, or providing integrations with other platforms. These service providers will have access to your personal data only to the extent necessary to perform their functions and are contractually obligated to maintain the confidentiality and security of your information.
                        <br/><br/>
                        3.2. Compliance with Law: We may disclose your personal information if required to do so by law or in response to valid requests by public authorities (e.g., a court or government agency).
                        <br/><br/>
                        4. Integrations with Other Platforms
                        <br/><br/>
                        4.1. Third-Party Integrations: In addition to the Slack integration mentioned in this Privacy Policy, we may offer integrations with other platforms, such as email services, SMS services, or other communication channels. These integrations may involve the collection, processing, and storage of personal data. Any such integrations will be governed by this Privacy Policy and any additional terms and conditions provided at the time of integration.
                        <br/><br/>
                        4.2. Data Security: We take reasonable measures to protect the data collected through integrations with other platforms. However, please note that no method of transmission over the internet or method of electronic storage is 100% secure. Therefore, while we strive to use commercially acceptable means to protect your data, we cannot guarantee its absolute security.
                        <br/><br/>
                        5. Your Choices
                        <br/><br/>
                        5.1. Account Information: You can update or correct your account information at any time by logging into your account settings. You may also request the deletion of your account by contacting us. Please note that we may retain certain information as required by law or for legitimate business purposes.
                        <br/><br/>
                        5.2. Communication Preferences: You can choose to opt out of receiving promotional communications from us by following the instructions provided in the communication or by contacting us directly. However, please note that we may still send you administrative notifications related to your account or the Service.
                        <br/><br/>
                        6. Children's Privacy
                        <br/><br/>
                        The Service is not intended for use by individuals under the age of 18. We do not knowingly collect personal information from children. If you are under the age of 18, please do not use or provide any personal information on the Service. If you believe that we may have collected personal information from a child without proper consent, please contact us, and we will promptly investigate and take appropriate action.
                        <br/><br/>
                        7. Data Retention
                        <br/><br/>
                        We will retain your personal data for as long as necessary to fulfill the purposes outlined in this Privacy Policy unless a longer retention period is required or permitted by law. The retention period will be determined based on the criteria of necessity, relevant legal obligations, and business needs.
                        <br/><br/>
                        8. Data Security Measures
                        <br/><br/>
                        We implement appropriate technical and organizational measures to safeguard the personal data we collect and store. These measures include encryption, access controls, regular security audits, and staff training on data protection. However, please note that no data transmission or storage system can be guaranteed to be 100% secure.
                        <br/><br/>
                        9. Data Transfer and Storage
                        <br/><br/>
                        In the course of providing the Service, your personal data may be transferred to and stored on servers located in countries other than your own. By using the Service, you consent to the transfer, storage, and processing of your personal data in these jurisdictions. We ensure that such transfers comply with applicable data protection laws, and we have implemented appropriate safeguards, such as standard contractual clauses or compliance with relevant data protection regulations, to protect your data.
                        <br/><br/>
                        10. Third-Party Services
                        <br/><br/>
                        The Service may integrate with third-party services or platforms, and the collection, use, and sharing of user data may occur as a result. We are not responsible for the privacy practices of these third-party services, and we recommend that you review their privacy policies and practices before using or interacting with them.
                        <br/><br/>
                        11. Data Breach Notification
                        <br/><br/>
                        In the event of a data breach that compromises the security of your personal data, we will take immediate steps to assess and mitigate the breach. If required by applicable laws, we will notify you and the relevant authorities within the timeframe specified by law. We will provide information about the nature of the breach, the types of data affected, and the measures we are taking to address the breach and prevent future occurrences.
                        <br/><br/>
                        12. Changes to this Privacy Policy
                        <br/><br/>
                        We may update this Privacy Policy from time to time by posting a revised version on our website or through the Service. The revised version will be effective as of the date of posting. We encourage you to review this Privacy Policy periodically to stay informed about our data practices. Your continued use of the Service signifies your acceptance of any changes to this Privacy Policy.
                        <br/><br/>
                        13. User Rights
                        <br/><br/>
                        We respect your rights regarding your personal data. You have the right to access, rectify, delete, and restrict the processing of your data. You can exercise these rights by contacting us directly. We will respond to your request in accordance with applicable data protection laws.
                        <br/><br/>
                        14. Cookies and Tracking Technologies
                        <br/><br/>
                        Our Service may use cookies or similar tracking technologies. By using the Service, you consent to the use of cookies and tracking technologies as described in our Cookie Policy. You can manage your cookie preferences through the settings of your web browser.
                        <br/><br/>
                        15. Cross-Border Data Transfers and User Consent
                        <br/><br/>
                        By using our Service, you expressly consent to the collection, processing, storage, transfer, and disclosure of your personal data as outlined in this Privacy Policy, including the transfer of your data to servers located in countries outside of your own jurisdiction. We take steps to ensure that such transfers comply with applicable data protection laws, and we have implemented appropriate safeguards, such as standard contractual clauses or compliance with relevant data protection regulations, to protect your data. Please note that the laws regarding data protection and privacy in these jurisdictions may differ from the laws in your own jurisdiction.
                        <br/><br/>
                        16. Complaints and Dispute Resolution
                        <br/><br/>
                        If you have any complaints or concerns regarding the handling of your personal data, please contact us using the information provided below. We will investigate and attempt to resolve any complaints or disputes regarding the use and disclosure of your data in accordance with this Privacy Policy.
                        <br/><br/>
                        17. Data Protection Officer (DPO)
                        <br/><br/>
                        We have appointed a Data Protection Officer (DPO) who is responsible for overseeing our data protection practices. If you have any questions or concerns about our data protection practices or this Privacy Policy, you can contact our DPO at hello@formulatelabs.com.
                        <br/><br/>
                        18. Data Subject Requests
                        <br/><br/>If you wish to exercise your rights as a data subject, such as requesting access to your personal data or requesting its deletion, you can submit a data subject request by contacting us using the information provided below. We will respond to your request within the timeframe required by applicable data protection laws.
                        <br/><br/>
                        19. Automated Decision-Making
                        <br/><br/>We may use automated decision-making processes, including profiling, to analyze user data and provide personalized experiences. However, we will not make decisions that have a significant legal or similarly significant effect on you solely based on automated processing.
                        <br/><br/>
                        20. GDPR Compliance Clause
                        <br/><br/>
                        If you are located in the European Union (EU) or if we collect, process, or store your personal data while you are in the EU, this GDPR Compliance Clause applies to you.
                        <br/><br/>
                        Legal Basis for Processing Personal Data: We process personal data in accordance with the provisions of the General Data Protection Regulation (GDPR). Our legal basis for processing personal data includes obtaining your explicit consent, fulfilling contractual obligations, compliance with legal obligations, protection of vital interests, performance of tasks carried out in the public interest or in the exercise of official authority, and legitimate interests pursued by us or a third party.
                        <br/><br/>
                        Data Controller: The data controller responsible for the processing of your personal data is Formulate Labs's PDO, with contact information provided at the end of this Privacy Policy.
                        <br/><br/>
                        Data Protection Officer (DPO): We have appointed a Data Protection Officer (DPO) who can be contacted at hello@formulatelabs.com. Our DPO is responsible for overseeing our data protection practices and ensuring compliance with the GDPR.
                        <br/><br/>
                        Your Rights Under the GDPR: As a data subject under the GDPR, you have the following rights:
                        <br/><br/>
                        Right of Access: You have the right to obtain confirmation from us as to whether or not personal data concerning you is being processed and, if so, access to that personal data.
                        <br/><br/>
                        Right to Rectification: You have the right to request the rectification of inaccurate personal data concerning you.
                        <br/><br/>
                        Right to Erasure (Right to be Forgotten): You have the right to request the deletion of your personal data under certain circumstances.
                        <br/><br/>
                        Right to Restriction of Processing: You have the right to request the restriction of processing of your personal data under certain circumstances.
                        <br/><br/>
                        Right to Data Portability: You have the right to receive the personal data concerning you in a structured, commonly used, and machine-readable format and to transmit those data to another controller.
                        <br/><br/>
                        Right to Object: You have the right to object, on grounds relating to your particular situation, to the processing of your personal data under certain circumstances.
                        <br/><br/>
                        Right to Withdraw Consent: If we rely on your consent as the legal basis for processing your personal data, you have the right to withdraw that consent at any time.
                        <br/><br/>
                        International Data Transfers: By using our Service and providing your personal data, you acknowledge and consent to the transfer, processing, and storage of your personal data outside of the European Economic Area (EEA) or the country in which you reside. We take appropriate safeguards to ensure that your personal data is protected in accordance with applicable data protection laws.
                        <br/><br/>
                        Complaints: If you believe that we have not complied with the provisions of the GDPR or have infringed your rights under the GDPR, please contact us using the contact information provided below. You also have the right to lodge a complaint with a supervisory authority.
                    </div>
                </div>
            </section>
        </>
    );
};

export default PrivacyDetails;
