import React, {useContext, useMemo} from 'react';
import {
   Button,
   Card,
   CardContent, CircularProgress,
   Grid, Tooltip,
   Typography
} from "@mui/material";
import {Add, ThumbDown, ThumbUp} from "@mui/icons-material";
import {prettyNumber} from "../../../lib/helpers/helpers";
import {DemoContext} from "../../../lib/contexts/DemoContext";
import {Link} from "react-router-dom";
const FAQSummary = () => {
   const {
      state: { summary, matchMD, matchSM, isAddingFaqAllowed, isLoadingCurrentUser, isRefetchingCurrentUser, isLoadingOrganizationSummary },
      actions: { handleNewFAQ },
   } = useContext(DemoContext);
   const mostHelpful = useMemo(()=>summary ? summary.most_helpful : '', [summary])
   const leastHelpful = useMemo(()=>summary ? summary.least_helpful : '', [summary])
    return (
        <>
           <Card style={{maxHeight:matchSM ? 400 : 260, height:matchSM ? 'auto' : 260}}>
              <CardContent>
                 <Grid container item xs={12} spacing={matchMD ? 1 : 4} style={{margin:'auto', marginLeft:-16}} justifyContent={'space-between'} alignContent={'space-between'} alignItems={'center'}>
                    <Grid item xs={10} style={{display:'none'}}>
                       <Typography variant={'body1'}><b>{prettyNumber(123)}%</b> Manually Reviewed Translations</Typography>
                       <Typography variant={'body2'} color={'textSecondary'}>Percentage of manually reviewed articles by language pair.</Typography>
                    </Grid>
                    
                    <Grid item xs={matchSM ? 11 : 6}>
                       <Link to={'../../dashboard/faqs/'+mostHelpful?.id} style={{color:'inherit', cursor:'pointer'}}>
                       <ThumbUp style={{float:'left', marginRight:10}}/>
                       <Typography variant={'body1'} style={{ marginBottom:10}}><b>Most Helpful</b></Typography>
                       <Typography variant={'body2'} color={'textSecondary'}>{isLoadingOrganizationSummary ? <CircularProgress size={20} /> : mostHelpful?.question ? '"'+mostHelpful.question+'"' : 'N/A'}</Typography>
                       </Link>
                    </Grid>
                    
                    <Grid item xs={matchSM ? 11 : 6}>
                       <Link to={'../../dashboard/faqs/'+(leastHelpful?.id ?? '')} style={{color:'inherit', cursor:'pointer'}}>
                       <ThumbDown style={{float:'left', marginRight:10}}/>
                       <Typography variant={'body1'} style={{ marginBottom:10}}><b>Least Helpful</b></Typography>
                       <Typography variant={'body2'} color={'textSecondary'}>{isLoadingOrganizationSummary ? <CircularProgress size={20} /> : leastHelpful?.question ? '"'+leastHelpful.question+'"' : 'N/A'}</Typography>
                       </Link>
                    </Grid>
   
                    <Grid item xs={12} style={{ margin: matchMD ? '20px auto auto' : 'auto', textAlign:'center', paddingLeft:0}}>
                       <Typography variant={'body1'}>{`Create ${(mostHelpful && mostHelpful.id) || leastHelpful && leastHelpful.id ? 'additional FAQs' : 'your initial FAQ'} to help train the AI.`}</Typography>
                       <Typography variant={'body2'} color={'textSecondary'}>Visitors have the ability to vote & indicate whether the provided information was helpful or not.</Typography>
                       <Button disabled={!isAddingFaqAllowed} variant={'outlined'} color={'primary'} style={{ margin:'20px auto auto', textAlign:'center'}} onClick={handleNewFAQ}>
                          <Tooltip title={'Add Item'}>
                             {isLoadingCurrentUser || isRefetchingCurrentUser ? <CircularProgress size={20} /> : <><Add/>&nbsp;New Item</> }
                          </Tooltip>
                       </Button>
                    </Grid>
                    
                 </Grid>
              </CardContent>
           </Card>

        </>
    );
};

export default FAQSummary;
