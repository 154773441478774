import axios from 'axios';
import { API_HOST } from '../constants/constants';
export const deleteOrganizationFaq = async (token, userID, responseID) => {
  const apiURL = API_HOST + '/organization_faqs/' + responseID;
  try {
    const { data } = await axios.delete(apiURL,{
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+token,
        'x-gg-user-id': userID
      }
    });
    return data.data;
  } catch (error) {
    console.error(error);
  }
};
