import * as React from 'react';
import {
  Box, Button, ButtonGroup,
  Chip, ClickAwayListener, DialogActions, Divider, FormControl, Grid, Grow,
  IconButton, InputAdornment, InputLabel,
  ListItem,
  ListItemIcon, ListItemSecondaryAction,
  ListItemText, Menu, MenuItem, MenuList,
  Paper, Popper, Select, Switch, TextField, Tooltip,
  Typography,
} from '@mui/material';
//import {ColorPicker} from "material-ui-color";
import { ColorPicker } from '@wellbees/color-picker-input'

import {
  Add,
  ArrowDropDown,
  Close,
  FilterList,
  Help,
  Notifications,
  NotificationsOff,
  Save,
  Star
} from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';

import List from '@mui/material/List';
import { useNavigate } from 'react-router-dom';
import useOrganizationFaqs from '../../lib/hooks/useOrganizationFaqs';
import {useCallback, useContext, useMemo, useState} from 'react';
import {toCapitalizeFromUnderScore, toUnderScoreSlug} from '../../lib/helpers/helpers';
import { useParams } from 'react-router';
import {getButtonBorderColor} from "../../lib/helpers/colorHelpers";
import {GlossaryContext} from "../../lib/contexts/GlossaryContext";
import {updateOrganization} from "../../lib/api";
import {DemoContext} from "../../lib/contexts/DemoContext";
import all_countries from "../../lib/constants/countries";
import {pricingData} from "../../lib/constants/constants";

const useStyles = makeStyles(theme => ({
  root: {
    display:'block',
    width: '100%',
    padding: 10,
    '& nav': {
      padding: '0px !important',
      margin: '0px !important',
    },
  },
  containerTop: {
    //maxHeight: '25vh',
    overflowY: 'auto',
    marginLeft:0,
    marginRight:0,
    '& *':{
      overflow: 'visible',
    }
  },
  container: {
   // maxHeight: '40vh',
    overflowY: 'auto',
  },
  chip: {
    margin: 8,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  icon: {
    color: theme.palette.primary.paperText,
  },
  button: {},
  countrySelect: {
    '&.MuiInput-underline:before': {
      borderBottom: '0px solid rgba(0, 0, 0, 0.42)',
    },
    '&.MuiInput-underline:after': {
      borderBottom: '0px solid rgba(0, 0, 0, 0.42)',
    },
  },
}));

export default function SidebarManagement() {
  const classes = useStyles();
  const previewOptions = ['Closed', 'Open', 'Conversation']
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  
  const {
    state: {
      isLoadingCurrentUser,
      isLoadingOrganization,
      matchSM,
      poweredBy,
      primaryColor,
      secondaryColor,
      tertiaryColor,
      quaternaryColor,
      companyAIName,
      companyName,
      companyDesc,
       chatPosition,
      companyStatus,
       companyWebsite,
      companyPhone,
      companyCountryPhone,
      companyEmail,
      companyEmailNotifications,
      showTeam,
      showFAQ,
       buttonGradient,
      collectEmail,
      collectPhone,
      autoPrompt,
      autoSlack,
      companyWebhook,
      companyAIPersonality,
      companyTheme,
       previewMode,
      currentPlan
    },
    actions: {
      refetchCurrentUser,
      handleNotificationSave,
      isFeatureAllowed,
      setPoweredBy,
      setPrimaryColor,
      setSecondaryColor,
      setTertiaryColor,
      setQuaternaryColor,
       setButtonGradient,
      setCompanyAIName,
      setCompanyName,
      setCompanyDesc,
      setCompanyWebsite,
      setCompanyPhone,
      setCompanyCountryPhone,
      setCompanyEmail,
      setCompanyAIPersonality,
      setCompanyTheme,
      setCompanyType,
      setChatPosition,
       setCompanyStatus,
       setCompanyWebhook,
       setShowTeam,
      setShowFAQ,
      setCollectEmail,
      setCollectPhone,
      setAutoPrompt,
       setAutoSlack,
      handleSave,
       setPreviewMode,
      enqueueSnackbar
       },
  } = useContext(DemoContext);
  const handleToggle = () => setOpen((prevOpen) => !prevOpen);
  const handleMenuItemClick = useCallback((event, index) => {
    setSelectedIndex(index)
    setPreviewMode(previewOptions[index])
    handleToggle()
  },[]);
  
  const personalityTypes = [
    {type: 'Professional, Formal, and Instructive', description: 'Communicates in a highly professional and formal manner, providing directions and advice in a clear, confident, and assertive manner.'},
    {type: 'Casual, Friendly, & Humorous', description: 'Characterized by a warm and friendly tone, uses casual language, emojis, displays a sense of humor, and frequently uses jokes and playful language to entertain users.'},
    {type: 'Empathetic, Supportive, & Therapeutic', description: 'Shows understanding and empathy, using language that conveys support and reassurance, and employs soothing language and therapeutic communication techniques.'},
    {type: 'Enthusiastic & Optimistic', description: 'Communicates with a lot of energy and enthusiasm, uses exclamation points and words that convey excitement and passion, and consistently conveys a positive outlook.'},
    {type: 'Calm, Mindful, & Philosophical', description: 'Uses mindful language promoting calmness and tranquility, and often poses thought-provoking questions or shares philosophical insights.'},
    {type: 'Mirrored Personality', description: 'Adapts its communication style to match that of the user, mirroring their tone, language, and level of formality.'},
    {type: 'Child-Friendly & Educative', description: 'Uses simple language, fun expressions, and engaging learning techniques suitable for children.'},
    {type: 'Tech-Savvy & Informative', description: 'Uses technical language and jargon, suitable for audiences with a high level of technical knowledge.'},
    {type: 'Scientific & Analytical', description: 'Uses a precise and data-driven approach, often providing information backed by facts and statistics.'},
    {type: 'Creative, Imaginative, & Narrative-driven', description: 'Uses a rich vocabulary and creative expressions, crafting responses to be unique and imaginative, and communicates in a descriptive, narrative style.'},
    {type: 'Straightforward and Direct', description: 'Provides information in a clear, direct, and no-nonsense manner, ideal for users who prefer simplicity and brevity.'},
  ];
  const getPersonalityCount = useCallback(() => {
    if (currentPlan) {
      const plan = pricingData.find(item => item.plan.toLowerCase() === currentPlan);
      return plan ? plan.aiPersonalities : 0;
    }
    return 0;
  },[currentPlan, pricingData]);
  
  
  return (
    <Paper className={classes.root}>
      <Grid
         container
         item xs={12}
         direction="row"
         justifyContent="space-evenly"
         alignItems="flex-start"
         spacing={2}
         className={classes.container}
      >
        <Grid item xs={matchSM ? 12 : 6}>
          <>
            <Typography variant={'subtitle1'} style={{ marginTop: 20, marginBottom: 20 }}>
              Information
            </Typography>
            <Box component="nav" aria-label="organization information" className={classes.containerTop}>
  
  
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <Tooltip title={"This is the name of your new employee! They will help represent your company."}>
                  <TextField
                     size={'small'}
                     key='ai_name'
                     id='ai_name'
                     defaultValue={'SavvyGPT'}
                     placeholder={'SavvyGPT'}
                     label='AI Employee Name'
                     value={companyAIName}
                     margin='normal'
                     variant='outlined'
                     fullWidth={true}
                     type={'text'}
                     onChange={()=>setCompanyAIName(event.target.value.replace(/[^a-zA-Z]/g, '').substring(0,10))}

                  />
                  </Tooltip>
                </Grid>
                <Grid item xs={12}>
                  <Tooltip title={"This is the name of the company the AI will represent."}>
                  <TextField
                     autoComplete={'no'}
                     size={'small'}
                     key='company_name'
                     id='company_name'
                     label='Company Name'
                     placeholder={'Acme Corp'}
                     value={companyName}
                     margin='normal'
                     variant='outlined'
                     fullWidth={true}
                     type={'text'}
                     onChange={()=>setCompanyName(event.target.value)}
                  />
                  </Tooltip>
                </Grid>
  
                <Grid item xs={12}>
                  <Tooltip title={"Provide the domain where the AI will be installed."}>
                    <TextField
                       required={true}
                       autoComplete={'no'}
                       size={'small'}
                       key='company_site'
                       id='company_site'
                       label='Company Website'
                       placeholder={'Example.com'}
                       value={companyWebsite}
                       margin='normal'
                       variant='outlined'
                       fullWidth={true}
                       type={'text'}
                       onChange={()=>setCompanyWebsite(event.target.value)}
                       InputProps={{
                         startAdornment: <InputAdornment position="start">
                           https://
                         </InputAdornment>,
                       }}
                    />
                  </Tooltip>
                </Grid>
                <Grid item xs={12}>
                  <Tooltip title={"This allows the AI to have additional context around your business & services."}>
                  <TextField
                     autoComplete={'no'}
                     size={'small'}
                     multiline={true}
                     minRows={4}
                     maxRows={4}
                     key='company_desc'
                     id='company_desc'
                     label='Company Description'
                     placeholder={"Acme Corp is a leading technology company dedicated to providing innovative solutions and services to businesses across various industries. Our mission is to empower organizations by offering cutting-edge software, exceptional customer support, and seamless integrations. With a strong focus on sustainability, Acme Corp is committed to driving positive change in both the marketplace and our local communities. Our diverse and talented team works diligently to create value for our clients, partners, and stakeholders while fostering a collaborative and inclusive work environment."}
                     value={companyDesc}
                     margin='normal'
                     variant='outlined'
                     fullWidth={true}
                     type={'text'}
                     onChange={()=>setCompanyDesc(event.target.value)}
                  />
                  </Tooltip>
                </Grid>
  
                <Grid item xs={12}>
                  <Tooltip title={"Provide the AI with a phone number to promote."}>
                  {/*  <TextField
                       autoComplete={'no'}
                       size={'small'}
                       key='company_phone'
                       id='company_phone'
                       label='Company Phone Number'
                       placeholder={'123-456-7890'}
                       value={companyPhone}
                       margin='normal'
                       variant='outlined'
                       fullWidth={true}
                       type={'text'}
                       onChange={()=>setCompanyPhone(event.target.value)}
                    />*/}
                    <TextField
                       autoComplete={'no'}
                       size={'small'}
                       key='company_phone'
                       id='company_phone'
                       label='Company Phone Number'
                       value={companyPhone}
                       margin='normal'
                       variant='outlined'
                       fullWidth={true}
                       type={'text'}
                       onChange={()=>setCompanyPhone(event.target.value.replace(/[^0-9]/g, '').substring(0,10))}
                       InputProps={{
                         maxLength: 10,
                         mask: ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
                         startAdornment: <InputAdornment position="start" style={{width:160}}>
                           <Select
                              aria-label="select from country phone codes"
                              value={companyCountryPhone}
                              variant={"standard"}
                              className={classes.countrySelect}
                           >
                             {all_countries.map((item)=> <MenuItem key={item.code} value={item.phone}
                                                                   onClick={(e)=>setCompanyCountryPhone(item.phone)}>
                                  <img style={{height:30,width:30}} src={item.flag}/>&nbsp;&nbsp;{item.phone}
                                </MenuItem>
                             )}
                           </Select>
                         </InputAdornment>,
                       }}
                    />
                  </Tooltip>
                </Grid>
                <Grid item xs={12}>
                  <Tooltip title={"Provide an email address the AI should promote when asked."}>
                    <TextField
                       autoComplete={'no'}
                       size={'small'}
                       key='company_email'
                       id='company_email'
                       label='Company Email'
                       placeholder={'notifications@example.com'}
                       value={companyEmail}
                       margin='normal'
                       variant='outlined'
                       fullWidth={true}
                       type={'text'}
                       onChange={()=>setCompanyEmail(event.target.value)}
                       InputProps={{
                         endAdornment: <InputAdornment position="end">
                           <Tooltip title={"Toggle your email notifications."}>
                           <IconButton
                             onClick={()=>isFeatureAllowed('email_notifications') ? handleNotificationSave() : enqueueSnackbar('Please upgrade to an eligible plan.', {variant:'error'})}
                             size="large">
                             {companyEmailNotifications ? <Notifications/> : <NotificationsOff/>}
                           </IconButton>
                           </Tooltip>
                         </InputAdornment>,
                       }}
                    />
                  </Tooltip>
                </Grid>
                <Grid item xs={12}>
                  <Tooltip title={"Webhooks enable you to obtain event notifications and information related to visitors and their interactions with the AI during conversations."}>
                    <TextField
                       autoComplete={'no'}
                       size={'small'}
                       key='company_endpoint'
                       id='company_endpoint'
                       label='Webhook Endpoint'
                       placeholder={"api.example.com/webhook"}
                       value={companyWebhook}
                       margin='normal'
                       variant='outlined'
                       fullWidth={true}
                       type={'text'}
                       onChange={()=>isFeatureAllowed('webhook_endpoint') ? setCompanyWebhook(event.target.value) : enqueueSnackbar('Please upgrade to an eligible plan.', {variant:'error'}) && setCompanyWebhook('')}
                       InputProps={{
                         startAdornment: <InputAdornment position="start">
                           https://
                         </InputAdornment>,
                       }}
                    />
                  </Tooltip>
                </Grid>
                <Grid item xs={12}>
                  <Tooltip title={"The status message provides organizations with a way to inform users about important updates or current situations before engaging in a live chat conversation, enhancing user experience and setting expectations."}>
                  <TextField
                     autoComplete={'no'}
                     size={'small'}
                     key='company_status'
                     id='company_status'
                     label='Latest Status'
                     placeholder={"Actively investigating an outage impacting users in the Southwest region."}
                     value={companyStatus}
                     margin='normal'
                     variant='outlined'
                     fullWidth={true}
                     type={'text'}
                     onChange={()=>isFeatureAllowed('status_message') ? setCompanyStatus(event.target.value) : enqueueSnackbar('Please upgrade to an eligible plan.', {variant:'error'}) && setCompanyStatus('')}
                  />
                  </Tooltip>
                </Grid>
                </Grid>
            </Box>
          </>

        </Grid>
        <Grid item xs={matchSM ? 12 : 6}>
          <>
            <Typography variant={'subtitle1'} style={{ marginTop: 20, marginBottom: 20 }}>
              Customize
            </Typography>
            <Box component="nav" aria-label="faq sidebar hashtags" className={classes.containerTop}>
                <Grid container spacing={2} style={{ marginTop: 2 }}>
                  <Grid item xs={6}>
                    <ColorPicker
                       disabled={isLoadingCurrentUser}
                       size={'small'}
                       fullWidth={true}
                       inputType="input"
                       pickerType='twitter'
                       colorShowType={'circle'}
                       id={'primaryColor'} value={primaryColor} onChange={value=>{
                      setPrimaryColor(value)
                    }} />
                  </Grid>
                  <Grid item xs={6}>
                    <ColorPicker
                       disabled={isLoadingCurrentUser}
                       size={'small'}
                       fullWidth={true}
                       inputType="input"
                       pickerType='twitter'
                       colorShowType={'circle'}
                       id={'secondaryColor'} value={secondaryColor} onChange={value=>{
                      setSecondaryColor(value)
                    }} />
                  </Grid>
                  <Grid item xs={6}>
                    <ColorPicker
                       disabled={isLoadingCurrentUser}
                       size={'small'}
                       fullWidth={true}
                       inputType="input"
                       pickerType='twitter'
                       colorShowType={'circle'}
                       id={'tertiaryColor'} value={tertiaryColor} onChange={value=>{
                      setTertiaryColor(value)
                    }} />
                  </Grid>
                  <Grid item xs={6}>
                    <ColorPicker
                       disabled={isLoadingCurrentUser}
                       size={'small'}
                       fullWidth={true}
                       inputType="input"
                       pickerType='twitter'
                       colorShowType={'circle'}
                       id={'quaternaryColor'} value={quaternaryColor} onChange={value=>{
                      setQuaternaryColor(value)
                    }} />
                  </Grid>
                </Grid>
  
              <List style={{width: '100%', margin: '0px auto auto'}}>
                <ListItem dense>
                  <Grid container item xs={12} spacing={2} justifyContent={'space-between'} alignItems={'flex-start'}>
                    <Grid item xs={6}>
                      <Tooltip title={'Personalize the overall theme of the embed. By default, it\'s set to Auto, which defers the choice to the user\'s device settings.'}>
                        <FormControl variant="outlined"
                                     size={'small'} style={{width:'100%', marginTop:16}}>
                          <InputLabel id="company-theme-label">Theme</InputLabel>
                          <Select
                             fullWidth={true}
                             labelId="company-theme-label"
                             id="company-theme-outlined"
                             value={companyTheme}
                             onChange={(e) => setCompanyTheme(e.target.value)}
                             label="Theme"
                          >
                            {['auto', 'light', 'dark'].map((item) => (
                               <MenuItem key={item}  value={item} title={toCapitalizeFromUnderScore(item)}>
                                 {toCapitalizeFromUnderScore(item)}
                               </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={6}>
                      <Tooltip title={'Switch between bottom left or right positioning.'}>
                        <FormControl variant="outlined"
                                     size={'small'} style={{width:'100%', marginTop:16}}>
                          <InputLabel id="company-position-label">Position</InputLabel>
                          <Select
                             fullWidth={true}
                             labelId="company-position-label"
                             id="company-position-outlined"
                             value={chatPosition}
                             onChange={(e) => setChatPosition(e.target.value)}
                             label="Position"
                          >
                            {['left', 'right'].map((item) => (
                               <MenuItem key={item}  value={item} title={toCapitalizeFromUnderScore(item)}>
                                 {toCapitalizeFromUnderScore(item)}
                               </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Tooltip>
                      </Grid>
                  </Grid>
                </ListItem>{/*
                <Tooltip title={'Personalize your AI employee\'s response style.'}>
                </Tooltip>*/}
                <ListItem dense>
                  <FormControl variant="outlined"
                               size={'small'} style={{width:'100%', marginTop:16}}>
                    <InputLabel id="company-personality-label">AI Tone / Personality</InputLabel>
                    <Select
                       fullWidth={true}
                       labelId="company-personality-label"
                       id="company-personality-outlined"
                       value={companyAIPersonality}
                       onChange={(e) => setCompanyAIPersonality(e.target.value)}
                       label="AI Personality"
                    >
                      {personalityTypes.map((personality, index) => (
                           <MenuItem key={personality.type}  value={personality.type} title={personality.description}
                                     disabled={index >= getPersonalityCount()}>
                             <Tooltip title={personality.description}><>
                             {personality.type}</>
                             </Tooltip>
                             </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </ListItem>
                <Tooltip title={'Toggles between solid and gradient styling.'}>
                <ListItem dense>
                  <ListItemText>Make Button Gradient?</ListItemText>
                  <ListItemSecondaryAction>
                    <Switch
                       size={'medium'} color={'primary'} checked={buttonGradient} onChange={()=>setButtonGradient(!buttonGradient)}/>
                  </ListItemSecondaryAction>
                </ListItem>
                </Tooltip>
  
                <Tooltip title={'Enable the FAQ quick-search to help minimize the amount of inbound conversations.'}>
                <ListItem dense>
                  <ListItemText>Show FAQ?</ListItemText>
                  <ListItemSecondaryAction>
                    <Switch
                       size={'medium'} color={'primary'} checked={showFAQ} onChange={()=>setShowFAQ(!showFAQ)}/>
                  </ListItemSecondaryAction>
                </ListItem>
                </Tooltip>
                <Tooltip title={'This attempts to show Slack profile photos and displays randomized photos as a fallback.'}>
                <ListItem dense>
                  <ListItemText>Show Team?</ListItemText>
                  <ListItemSecondaryAction>
                    <Switch
                       size={'medium'} color={'primary'} checked={showTeam} onChange={()=>setShowTeam(!showTeam)}/>
                  </ListItemSecondaryAction>
                </ListItem>
                </Tooltip>
                <Tooltip title={'Show support!'}>
                <ListItem dense>
                  <ListItemText>Show &quot;Powered By&quot;</ListItemText>
                  <ListItemSecondaryAction>
                    <Switch
                       disabled={!isFeatureAllowed('show_powered_by')}
                       size={'medium'} color={'primary'} checked={poweredBy} onChange={()=>setPoweredBy(!poweredBy)}/>
                  </ListItemSecondaryAction>
                </ListItem>
                </Tooltip>
                
                <Divider />
  
                <Tooltip title={'Require visitors to enter an email before starting a conversation. If Capture Phone is active, they can provide their phone number instead.'}>
                <ListItem dense>
                  <ListItemText>Capture Email?</ListItemText>
                  <ListItemSecondaryAction>
                    <Switch
                       disabled={isLoadingCurrentUser}
                       onChange={()=>isFeatureAllowed('capture_email') ? setCollectEmail(!collectEmail) : enqueueSnackbar('Please upgrade to an eligible plan.', {variant:'error'})}
                          size={'medium'} color={'secondary'} checked={collectEmail}/>
                  </ListItemSecondaryAction>
                </ListItem>
                </Tooltip>
                <Tooltip title={'Require visitors to enter a phone number before starting a conversation. If Capture Email is active, they can provide their email instead.'}>
                <ListItem dense>
                  <ListItemText>Capture Phone?</ListItemText>
                  <ListItemSecondaryAction>
                    <Switch
                       disabled={isLoadingCurrentUser}
                       onChange={()=>isFeatureAllowed('capture_phone') ? setCollectPhone(!collectPhone) : enqueueSnackbar('Please upgrade to an eligible plan.', {variant:'error'})}
                       size={'medium'} color={'secondary'} checked={collectPhone}/>
                  </ListItemSecondaryAction>
                </ListItem>
                </Tooltip>
                <Tooltip title={'Greet and offer assistance to visitors as they load the page.'}>
                <ListItem dense>
                  <ListItemText>Auto-Prompt Visitor onLoad?</ListItemText>
                  <ListItemSecondaryAction>
                    <Switch
                       disabled={isLoadingCurrentUser}
                       size={'medium'} color={'secondary'} checked={autoPrompt} onChange={()=>setAutoPrompt(!autoPrompt)}/>
                  </ListItemSecondaryAction>
                </ListItem>
                </Tooltip>
                <Tooltip title={'By forwarding messages to Slack, you can oversee your AI employee\'s interactions with visitors and, if needed, take over the conversation.'}>
                <ListItem dense>
                  <ListItemText>Forward to Slack?</ListItemText>
                  <ListItemSecondaryAction>
                    <Switch
                       disabled={isLoadingCurrentUser}
                       onChange={()=>isFeatureAllowed('auto_forward_to_slack') ? setAutoSlack(!autoSlack) : enqueueSnackbar('Please upgrade to an eligible plan.', {variant:'error'})}
                          size={'medium'} color={'secondary'} checked={autoSlack}/>
                  </ListItemSecondaryAction>
                </ListItem>
                </Tooltip>
              </List>
            </Box>
          </>
        </Grid>
      </Grid>
      <DialogActions>
        <ButtonGroup variant="outlined" color="primary" ref={anchorRef} aria-label="split button" style={{display:'none'}}>
          <Button onClick={handleToggle}>Preview</Button>
          <Button
             color="primary"
             size="small"
             aria-controls={open ? 'split-button-menu' : undefined}
             aria-expanded={open ? 'true' : undefined}
             aria-label="select preview mode"
             aria-haspopup="menu"
             onClick={handleToggle}
          >
            <ArrowDropDown />
          </Button>
        </ButtonGroup>
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
             <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                }}
             >
               <Paper>
                 <ClickAwayListener onClickAway={handleToggle}>
                   <MenuList id="split-button-menu">
                     {previewOptions.map((option, index) => (
                        <MenuItem
                           key={option}
                           disabled={index === selectedIndex}
                           selected={index === selectedIndex}
                           onClick={(event) => handleMenuItemClick(event, index)}
                        >
                          {option}
                        </MenuItem>
                     ))}
                   </MenuList>
                 </ClickAwayListener>
               </Paper>
             </Grow>
          )}
        </Popper>
        
        
        
        <Button size={'medium'} variant={'outlined'} color={'primary'} onClick={handleSave}><Save/>&nbsp;Save</Button>
      </DialogActions>
    </Paper>
  );
}
