import { useQuery } from 'react-query';
import { getActivity } from '../api/getActivity';

const useActivity = (contactId, type) => {
  const result = useQuery('activity_'+(type ?? 'contact')+'_'+(contactId ?? 'all'), () => getActivity(contactId, type), {
    refetchOnWindowFocus: false,
    staleTime: 50000,
  });
  return result;
};

export default useActivity;
