import React, {useMemo} from 'react';
import Header from '../../Shared/Header';
import BreadCums from '../../Shared/BreadCums';
import BlogDetailsData from './BlogDetailsData';
import Apps from '../../Shared/Apps';
import useBlogPosts from "../../../lib/hooks/useBlogPosts";
import {useParams} from "react-router-dom";
import {toUnderScoreSlug} from "../../../lib/helpers/helpers";
import Footer3 from "../../Shared/Footer3";

const BlogDetails = () => {
   const { slug } = useParams();
   const {data:blogPosts, isLoading} = useBlogPosts()
   const chosenArticle = useMemo(() => blogPosts ? blogPosts.filter(item => toUnderScoreSlug(item.title) == slug)[0] : {}, [blogPosts, slug]);
   return (
        <>
            <Header
                parentMenu='blog'
                headerClass='sc-header-section inner-header-style'
                signUpBtn='inner-btn'
            />
            <BreadCums short={true}/>
            <BlogDetailsData chosenArticle={chosenArticle}/>
            <Apps />
           <Footer3 />
        </>
    );
};

export default BlogDetails;
