import React, {useContext} from 'react';
import {
   Card,
   CardContent, CircularProgress,
   FormHelperText,
   Grid, IconButton,
   List,
   ListItem,
   ListItemText,
   Tooltip,
   Typography
} from "@mui/material";
import useOrganizationActivity from "../../../lib/hooks/useOrganizationActivity";
import {prettyDateTime, toCapitalizeFromUnderScore, truncate} from "../../../lib/helpers/helpers";
import {DemoContext} from "../../../lib/contexts/DemoContext";
import {Refresh} from "@mui/icons-material";
const ActivityDetails = () => {
   const {
      state: { matchMD, matchSM },
   } = useContext(DemoContext);
   const {
      isLoading:isLoading,
      isRefetching:isRefetching,
      data: activityData,
      refetch: refetchFaq,
   } = useOrganizationActivity();
   
   const InternalTooltip = React.forwardRef((props, ref) => (
      <Tooltip {...props} ref={ref} />
   ));
   
   return <>
      <Card style={{maxHeight:matchSM ? 'auto' : 500, height:matchSM ? 'auto' : 500}}>
         <CardContent>
            <Grid container item xs={12} spacing={matchMD ? 1 : 4} style={{margin:'auto'}}>
               <Grid item xs={matchMD ? 9 : 8}>
                  <Typography variant={'body1'}>Visitor & Staff Activity</Typography>
                  <Typography variant={'body2'} color={'textSecondary'}>Historical Events</Typography>
               </Grid>
               <Grid item xs={matchMD ? 3 : 4}>
                     <IconButton
                        disabled={isLoading || isRefetching}
                        onClick={refetchFaq}
                        style={{margin:'auto',textAlign:'center'}}
                        size="large">
                      <Refresh/>
                  </IconButton>
               </Grid>
            </Grid>
            <hr/>
            <Grid container item xs={12} spacing={matchMD ? 1 : 4} style={{margin:'auto',overflowY:'auto', maxHeight:360}}>
               {
                  isLoading || isRefetching  ?
                     <div style={{margin: '150px auto 200px', textAlign: 'center'}}>
                        <CircularProgress size={'30px'} style={{marginLeft: -30, textAlign: 'center'}} />
                     </div>
                     :
                     !isLoading && !isRefetching && Boolean(activityData && activityData.length) ?
                     <>
                     <List>
               {activityData.map((item) => (
                        <ListItem key={item.id}>
                           <Tooltip title={
                              [
                                 [item.region, item.country].filter(Boolean).join(', '),
                                 item.ip_address,
                                 item.device_type,
                                 item.user_agent,
                                 item.referrer,
                              ].filter(Boolean).join(' - ')
                           }>
                              <>
                              <ListItemText
                                 primary={
                                    item && item.user_id
                                       ? (
                                          <>
                                             <b>Staff: </b> { item.entity_id && item.event_type.toLowerCase().includes('faq') ?
                                             <a href={`../../dashboard/faqs/${item.entity_id}`} style={{color:'inherit'}}>{toCapitalizeFromUnderScore(item.event_type || '')}</a>
                                             :
                                                toCapitalizeFromUnderScore(item.event_type || '')
                                             }
                                          </>
                                       )
                                       : item && item.contact_id
                                          ? (
                                             <>
                                                <b>Contact: </b> {toCapitalizeFromUnderScore(item.event_type || '')}
                                             </>
                                          )
                                          : item && item.event_type === 'ai_response' ? (
                                                <>
                                                   <b>AI Response: </b>
                                                </>
                                             )
                                             :
                                             (
                                                <>
                                                   <b>Visitor: </b> {toCapitalizeFromUnderScore(item.event_type || '')}
                                                </>
                                             )
                                 }
                                 secondary={<><b>{toCapitalizeFromUnderScore(truncate(item.event_value || '',38))}</b><br/><FormHelperText style={{margin: 'auto'}}>{prettyDateTime(item.updated_at)}{item.region ? <>&nbsp;|&nbsp;{item.region}</> : ''}{item.country ? ', ' : ''}{item.country}</FormHelperText></>}
                              />
                              
                           </>
                           </Tooltip>
                        </ListItem>
               )) }
                     </List>
               <Grid item xs={12} style={{display:'none'}}>
                  <Typography variant={'body2'}  color={'textSecondary'} style={{textAlign:'center'}}>View All</Typography>
               </Grid>
                     </>
                     : <FormHelperText style={{margin:'auto'}}>None Found</FormHelperText>
               }
            </Grid>
         </CardContent>
      </Card>
   </>;
};
export default ActivityDetails;
