import { useQuery } from 'react-query';
import {getBlogPosts} from "../api";
const useBlogPosts = () => {
  const result = useQuery('blog_posts', () => getBlogPosts(), {
    refetchOnWindowFocus: false,
    staleTime: 60000,
  });
  return result;
};

export default useBlogPosts;
