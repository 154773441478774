import React, {useEffect, useMemo, useState} from 'react';
import { Fade } from '@mui/material';
import { HashLink as Link } from 'react-router-hash-link';
import priceImg from '../../../assets/images/feature/price_img.png'
import priceImg1 from '../../../assets/images/feature/price_img1.png'
import priceImg2 from '../../../assets/images/feature/price_img2.png'
import priceCheck from '../../../assets/images/icons/price-check.png'
import sectionBg10 from '../../../assets/images/banner/section-bg10.png'
import sectionBg11 from '../../../assets/images/banner/section-bg11.png'
import {pricingData} from "../../../lib/constants/constants";
import {useSnackbar} from "notistack";

const Home4Price = () => {
    let [priceToggle, setPriceToggle] = useState(false)
    const production = useMemo(()=>window.location.hostname === 'savvygpt.co',[]);
    const signingUp = useMemo(()=>window.location.search.includes('signup=true'),[])
    const { enqueueSnackbar } = useSnackbar();
    useEffect(()=>{
        if(signingUp){
            enqueueSnackbar('No account found! Please choose a plan to get started.', {variant:'warning'});
        }
    },[signingUp])
        const renderPricingCards = (isAnnual) => (
       pricingData.map((item, index) => {
           if (item.isVisible) {
               return (
                  <div key={index} className="col-lg-3 col-md-6 col-sm-12 mb-5">
                      <Fade in={true} timeout={100 * index}>
                          <div className="sc-price-box p-z-idex">
                              <div className="price-box d-flex align-items-center">
                                  <div className="sc-price-image">
                                      <img src={priceImg} alt="Images" />
                                  </div>
                                  <div className="price-item">
                                      <h4 className="pric-title">{item.plan}</h4>
                                      <div className="sc-price">{isAnnual ? `$${item.annual_price}/m` : `$${item.price}`}</div>
                                  </div>
                              </div>
                              <div className="price-list">
                                  <ul className="list">
                                      <li><img src={priceCheck} alt="Check" /> <b>{item.aiResponses}</b> AI-Responses</li>
                                      <li><img src={priceCheck} alt="Check" /> <b>{item.faq}</b> FAQ</li>
                                      <li><img src={priceCheck} alt="Check" /> <b>{item.aiPersonalities}</b> AI Personalities</li>
                                      {item.otherFeatures.map((feature, featureIndex) => (
                                         <li key={featureIndex}><img src={priceCheck} alt="Check" /> {feature}</li>
                                      ))}
                                  </ul>
                              </div>
                              <div className="sc-primary-btn">
                                  <Link className="sc-gradiant-btn" to={isAnnual ? production ? item.annual_payment_link : item.dev_annual_payment_link : production ? item.payment_link : item.dev_payment_link}><span>Start</span></Link>
                              </div>
                          </div>
                      </Fade>
                  </div>
               );
           }
           return null;
       })
    );
    return (
        <>
            <section className='section-bg-shape2'>
                <div className="sc-price-style p-z-idex sc-pb-130 sc-md-pb-70">
                    <div className="container mx-auto max-width-100" style={{ maxWidth: 1400 }}>
                        <div className="sc-heading-area sc-mb-40 sc-md-mb-30 text-center">
                            <h2 className="title sc-mb-25"><span className="gradiant-color">Transparent</span> Pricing</h2>
                        </div>
                        <div className="pricing-selector-two">
                            <form>
                                <button type="button" className="pricing-monthly-btn">Monthly</button>
                                <input type="checkbox" name="pricing-selector" id="pricing-selector" onChange={() => setPriceToggle(!priceToggle)} />
                                <button type="button" className="pricing-yearly-btn">Yearly <span>(25% Off)</span></button>
                            </form>
                        </div>
                        <div className={`${priceToggle ? 'pricing-monthly d-none' : 'pricing-monthly'}`}>
                            <div className="row">
                                {renderPricingCards(false)}
                            </div>
                        </div>
                        <div className={`${priceToggle ? 'pricing-yearly' : 'pricing-yearly d-none'}`}>
                            <div className="row">
                                {renderPricingCards(true)}
                            </div>
                        </div>
                        <div className="d-block sc-primary-btn width-40 text-center mx-auto" style={{width:200}}>
                            <a href={'../../pricing/free'} className="sc-gradiant-btn"><span>Free Plan</span></a>
                        </div>
                    </div>
                </div>
                <div className="sc-section-bg2">
                    <img className="bg-image11" src={sectionBg10} alt="Bg" />
                    <img className="bg-image12" src={sectionBg11} alt="Bg" />
                </div>
            </section>
        </>
    );
};

export default Home4Price;
