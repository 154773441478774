import React from 'react';

const faqData = [
   {
      id: 1,
      question: "What payment methods does SavvyGPT accept?",
      answer: "SavvyGPT accepts all major credit cards and payment methods."
   },
   {
      id: 2,
      question: "Can I upgrade or downgrade my plan at any time?",
      answer: "Yes, you can upgrade or downgrade your SavvyGPT plan at any time. Changes will be applied from the next billing cycle."
   },
   {
      id: 3,
      question: "Is there a discount for annual plans?",
      answer: "Yes, SavvyGPT offers a 25% discount on all annual plans when compared to their monthly counterparts."
   },
   {
      id: 4,
      question: "Do you offer a free trial?",
      answer: "SavvyGPT offers a free plan with limited features, which allows you to test the service and decide if it's the right fit for your business."
   },
   {
      id: 5,
      question: "Are there any additional fees or hidden costs?",
      answer: "No, there are no additional fees or hidden costs. The prices displayed on our pricing page are transparent and inclusive of all features offered within each plan. We believe in clear and straightforward pricing, so you know exactly what you're paying for."
   },
   {
      id: 6,
      question: "Do you offer discounts for non-profit organizations?",
      answer: "Yes, we offer a discounted pricing for non-profit organizations. To apply for the non-profit discount, please contact our support team with documentation confirming your non-profit status, and we'll help you get set up with the discounted pricing."
   },
   {
      id: 7,
      question: "What is your refund policy?",
      answer: "Due to the nature of our service, we do not offer refunds. We encourage you to thoroughly review our features, benefits, and pricing before making a purchase decision. If you have any questions or concerns, please reach out to our support team who will be more than happy to assist you."
   },
];

const Accordion = () => {
   return (
      <>
         <div className="accordion sc-faq-style" id="accordionExample">
            {faqData.map((item) => (
               <div className="accordion-item" key={item.id}>
                  <h2 className="accordion-header" id={`heading${item.id}`}>
                     <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#collapse${item.id}`}
                        aria-expanded="true"
                        aria-controls={`collapse${item.id}`}>
                        {item.question}
                     </button>
                  </h2>
                  <div
                     id={`collapse${item.id}`}
                     className={`accordion-collapse collapse${item.id === 1 ? ' show' : ''}`}
                     aria-labelledby={`heading${item.id}`}
                     data-bs-parent="#accordionExample">
                     <div className="accordion-body">
                        <strong>{item.answer}</strong>
                     </div>
                  </div>
               </div>
            ))}
         </div>
      </>
   );
};

export default Accordion;
