import React, {useContext, useMemo} from 'react';
import {Card, CardContent, CircularProgress, Grid, IconButton, Typography} from "@mui/material";
import Chart from "react-apexcharts";
import {prettyNumber} from "../../../lib/helpers/helpers";
import {DemoContext} from "../../../lib/contexts/DemoContext";
import {Refresh} from "@mui/icons-material";
const ChartDetails = () => {
   const {
      state: { summary, isLoadingOrganizationSummary, isRefetchingOrganizationSummary, matchSM, matchMD },
      actions: { refetchOrganizationSummary },
   } = useContext(DemoContext);
   const analytics = useMemo(()=>summary?.analytics || [], [summary])
   const totalVisits = useMemo(()=>analytics?.visits?.reduce((a, b) => a + b, 0) || 0, [analytics])
   const totalResponses = useMemo(()=>analytics?.replied?.reduce((a, b) => a + b, 0) || 0, [analytics])
   const visitorSeries = useMemo(()=>analytics?.visits?.map(item => item === 0 ? null : item).map(item => prettyNumber(item)) || [], [analytics])
   const sentSeries = useMemo(()=>analytics?.sent?.map(item => item === 0 ? null : item).map(item => prettyNumber(item)) || [], [analytics])
   const repliedSeries = useMemo(()=>analytics?.replied?.map(item => item === 0 ? null : item).map(item => prettyNumber(item)) || [], [analytics])
    return (
        <>
           <Card style={{maxHeight:matchSM ? 'auto' : 500, height:matchSM ? 'auto' : 500}}>
              <CardContent>
                 <Grid container item xs={12} spacing={matchMD ? 1 : 4} style={{margin:'auto auto 20px'}}>
                    <Grid item xs={matchMD ? 12 : 10}>
                       <Typography variant={'body1'}>{isLoadingOrganizationSummary || !analytics || !analytics.visits ? <CircularProgress size={'20px'}/> : <><b>{prettyNumber(totalVisits)}</b> Visitors | <b>{prettyNumber(totalResponses)}</b> Conversations</>}</Typography>
                       <Typography variant={'body2'} color={'textSecondary'}>Number of Unique Visitors & Chats for the last 30 days.</Typography>
                    </Grid>
   
                    <Grid item xs={2}>
                       <IconButton
                          disabled={isLoadingOrganizationSummary || isRefetchingOrganizationSummary}
                          onClick={refetchOrganizationSummary}
                          style={{margin:'auto',textAlign:'center'}}
                          size="large">
                          <Refresh/>
                       </IconButton>
                    </Grid>
                 </Grid>
                 <hr/>
                 <Grid container item xs={12} spacing={matchMD ? 1 : 4} style={{margin:'auto', textAlign:'center'}}>
                    {isLoadingOrganizationSummary ? <CircularProgress size={'30px'} style={{margin:'165px auto 165px', textAlign:'center'}}/> : <Chart
  style={{ margin: 'auto', display: 'block' }}
  options={{
    colors: ['#DD5191', '#DD8976', '#F1C660'],
    chart: {
      type: 'line',
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '80%',
        endingShape: 'rounded',
      },
    },
    animations: {
      enabled: true,
      easing: 'linear',
      dynamicAnimation: {
        speed: 1000,
      },
    },
    stroke: {
      curve: 'smooth',
    },
    dataLabels: {
      enabled: false,
    },
     markers: {
        size: 5,
        showNullDataPoints: false,
     },
    xaxis: {
      categories: analytics && analytics.labels ? analytics.labels.map(item => item) : [],
      labels: {
        show: true,
        style: {
          colors: '#00000099',
        },
      },
    },
    yaxis: {
      labels: {
        show: true,
        style: {
          colors: '#00000099',
        },
      },
    },
    legend: {
      labels: {
        show: true,
        useSeriesColors: true,
      },
    },
  }}
  series={[
    {
       type: 'area',
      name: 'Visitors',
      data: visitorSeries,
   
       fill: {
          type: "gradient",
          gradient: {
             shadeIntensity: 1,
             opacityFrom: 0.2,
             opacityTo: 0.8,
             stops: [0, 20, 30, 40, 50, 60, 70, 80, 90, 100]
          }
       },
      
    },
    {
       type: 'bar',
      name: 'Chats',
      data: sentSeries,
   
       fill: {
          type: "gradient",
          gradient: {
             shadeIntensity: 1,
             opacityFrom: 0.2,
             opacityTo: 0.8,
             stops: [0, 20, 30, 40, 50, 60, 70, 80, 90, 100]
          }
       },
    },
    {
       type: 'scatter',
      name: 'AI Responses',
      data: repliedSeries,
    },
  ]}
  type="line"
  height={350}
  width={matchSM ? 300 : matchMD ? 350 : 600}
/>}
                 </Grid>
              </CardContent>
           </Card>
        </>
    );
};

export default ChartDetails;
