import React from 'react';
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import clientImg from '../../../assets/images/icons/client_img.png'
import clientImg1 from '../../../assets/images/icons/client_img1.png'
import clientImg2 from '../../../assets/images/icons/client_img2.png'
import clientImg3 from '../../../assets/images/icons/client-img3.png'
import sectionBg12 from '../../../assets/images/banner/section-bg12.png'
import sectionBg13 from '../../../assets/images/banner/section-bg13.png'

const Home4Testimonial = () => {

    const CustomRightArrow = ({ onClick }) => {
        return <button className='commonArrow arrowRight' onClick={() => onClick()}><i className="ri-arrow-right-line"></i></button>;
    };

    const CustomLeftArrow = ({ onClick }) => {
        return <button className='commonArrow arrowLeft' onClick={() => onClick()}><i className="ri-arrow-left-line"></i></button>;
    };

    return (
        <>
            <section className="section-bg-shape3">
                <div className="sc-client-style-two p-z-idex sc-pb-140 sc-md-pb-80">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-lg-4">
                                <div className="sc-heading-area sc-mb-55 sc-md-mb-45">
                                    <h2 className="heading-title sc-mb-25">What Customers Say About <span className="gradiant-color">Us</span></h2>
                                </div>
                            </div>
                        </div>
                        <div className="client4-carousel-wrapper">
                            <Carousel
                                className="sc-client-area"
                                infinite={true}
                                draggable={true}
                                arrows={true}
                                pauseOnHover={true}
                                slidesToSlide={1}
                                swipeable={true}
                                autoPlay={true}
                                autoPlaySpeed={2000}
                                customRightArrow={<CustomRightArrow />}
                                customLeftArrow={<CustomLeftArrow />}
                                responsive={{
                                    desktop: {
                                        breakpoint: {
                                            max: 3000,
                                            min: 992
                                        },
                                        items: 3,
                                    },
                                    tablet: {
                                        breakpoint: {
                                            max: 991,
                                            min: 577
                                        },
                                        items: 2,
                                    },
                                    mobile: {
                                        breakpoint: {
                                            max: 576,
                                            min: 1
                                        },
                                        items: 1,
                                    }
                                }}
                            >
                                <div className="sc-client-item p-z-idex">
                                    <div className="client_img sc-mb-25">
                                        <img src={clientImg3} alt="Client" />
                                    </div>
                                    <div className="description sc-mb-15">
                                        SavvyGPT has been a game-changer for our business. Its AI-driven live chat has not only reduced wait times for our customers but also improved their overall experience. The ability to tailor the AI's personality to match our brand has been a MASSIVE selling point. Highly recommended!
                                    </div>
                                    <div className="client-rating sc-mb-15">
                                        <ul>
                                            <li><i className="ri-star-fill"></i></li>
                                            <li><i className="ri-star-fill"></i></li>
                                            <li><i className="ri-star-fill"></i></li>
                                            <li><i className="ri-star-fill"></i></li>
                                            <li><i className="ri-star-fill"></i></li>
                                        </ul>
                                    </div>
                                    <div className="sc-client-author d-flex align-items-center">
                                        <div className="author-image sc-mr-15">
                                            <img src={clientImg} alt="Client" />
                                        </div>
                                        <div className="author-text">
                                            <h5 className="client-title sc-mb-0">Scott W</h5>
                                            <span>Product Manager</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="sc-client-item p-z-idex">
                                    <div className="client_img sc-mb-25">
                                        <img src={clientImg3} alt="Client" />
                                    </div>
                                    <div className="description sc-mb-15">
                                        As a small business owner, SavvyGPT has been invaluable in helping us manage customer inquiries without hiring additional staff. The integrated Knowledge Base has empowered our customers to find answers on their own, while the AI Live Chat ensures they always receive prompt and personalized assistance.
                                    </div>
                                    <div className="client-rating sc-mb-15">
                                        <ul>
                                            <li><i className="ri-star-fill"></i></li>
                                            <li><i className="ri-star-fill"></i></li>
                                            <li><i className="ri-star-fill"></i></li>
                                            <li><i className="ri-star-fill"></i></li>
                                            <li><i className="ri-star-line"></i></li>
                                        </ul>
                                    </div>
                                    <div className="sc-client-author d-flex align-items-center">
                                        <div className="author-image sc-mr-15">
                                            <img src={clientImg1} alt="Client" />
                                        </div>
                                        <div className="author-text">
                                            <h5 className="client-title sc-mb-0">Dianne R</h5>
                                            <span>Product Designer</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="sc-client-item p-z-idex">
                                    <div className="client_img sc-mb-25">
                                        <img src={clientImg3} alt="Client" />
                                    </div>
                                    <div className="description sc-mb-15">
                                        "Our team has been using SavvyGPT for a few months now, and we're thrilled with the results. The AI chatbot handles a significant portion of our customer inquiries, freeing up our support agents to focus on more complex issues. The analytics provided have also helped us identify areas for improvement and better understand our customers' needs.
                                    </div>
                                    <div className="client-rating sc-mb-15">
                                        <ul>
                                            <li><i className="ri-star-fill"></i></li>
                                            <li><i className="ri-star-fill"></i></li>
                                            <li><i className="ri-star-fill"></i></li>
                                            <li><i className="ri-star-fill"></i></li>
                                            <li><i className="ri-star-fill"></i></li>
                                        </ul>
                                    </div>
                                    <div className="sc-client-author d-flex align-items-center">
                                        <div className="author-image sc-mr-15">
                                            <img src={clientImg2} alt="Client" />
                                        </div>
                                        <div className="author-text">
                                            <h5 className="client-title sc-mb-0">Robert T</h5>
                                            <span>Integration Director</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="sc-client-item p-z-idex">
                                    <div className="client_img sc-mb-25">
                                        <img src={clientImg3} alt="Client" />
                                    </div>
                                    <div className="description sc-mb-15">
                                        The scalability and customizability of SavvyGPT have been critical to our growing business. As we expand into new markets, the AI's auto-translation feature has made it easy to communicate with customers in different languages. We've also been able to maintain a consistent brand voice across our support channels thanks to the AI's customizable personality.
                                    </div>
                                    <div className="client-rating sc-mb-15">
                                        <ul>
                                            <li><i className="ri-star-fill"></i></li>
                                            <li><i className="ri-star-fill"></i></li>
                                            <li><i className="ri-star-fill"></i></li>
                                            <li><i className="ri-star-fill"></i></li>
                                            <li><i className="ri-star-fill"></i></li>
                                        </ul>
                                    </div>
                                    <div className="sc-client-author d-flex align-items-center">
                                        <div className="author-image sc-mr-15">
                                            <img src={clientImg1} alt="Client" />
                                        </div>
                                        <div className="author-text">
                                            <h5 className="client-title sc-mb-0">Hunter H</h5>
                                            <span>Agency Owner</span>
                                        </div>
                                    </div>
                                </div>
                            </Carousel>
                        </div>
                    </div>
                </div>
                <div className="sc-section-bg3">
                    <img className="bg-image13" src={sectionBg12} alt="Bg" />
                    <img className="bg-image14" src={sectionBg13} alt="Bg" />
                </div>
            </section>
        </>
    );
};

export default Home4Testimonial;
