import { useQuery } from 'react-query';
import {getOrganizationActivity} from "../api";
import {useContext} from "react";
import {DemoContext} from "../contexts/DemoContext";
import useCurrentUser from "./useCurrentUser";

const useOrganizationActivity = () => {
  const { token, userID } = useCurrentUser();
  const {
    state: { api_key },
  } = useContext(DemoContext);
  const result = useQuery('organization_activity_'+api_key, () => getOrganizationActivity(token, userID), {
    refetchOnWindowFocus: false,
    staleTime: 5000,
  });
  return result;
};

export default useOrganizationActivity;
