import React from 'react';
const faqData = [
    {
        id: 1,
        question: "How does SavvyGPT's AI Live Chat work?",
        answer: "SavvyGPT uses advanced artificial intelligence to understand and respond to customer inquiries in real-time, offering immediate and personalized assistance."
    },
    {
        id: 2,
        question: "What is the Knowledge Base?",
        answer: "The Knowledge Base is an integrated repository of information on products, services, and other relevant topics, enabling customers to find answers quickly and independently."
    },
    {
        id: 3,
        question: "Can I customize the AI's personality and appearance?",
        answer: "Yes, SavvyGPT allows you to tailor the AI's personality and appearance to match your brand's voice and style."
    },
    {
        id: 4,
        question: "Is SavvyGPT available in multiple languages?",
        answer: "Yes, SavvyGPT offers auto-translation to help you communicate with customers in different languages."
    },
    {
        id: 5,
        question: "How does SavvyGPT help reduce customer support workload?",
        answer: "By handling a significant portion of customer inquiries, SavvyGPT frees up your human support agents to focus on more complex issues."
    },
    {
        id: 6,
        question: "How does SavvyGPT improve customer satisfaction?",
        answer: "SavvyGPT's AI-driven live chat offers immediate assistance, personalized responses, and consistent support quality, enhancing the overall customer experience."
    },
    {
        id: 7,
        question: "Can I integrate SavvyGPT with other platforms like Slack?",
        answer: "Yes, SavvyGPT offers integration with platforms like Slack for seamless communication and collaboration."
    },
    {
        id: 8,
        question: "Is there a free plan available?",
        answer: "SavvyGPT offers a free plan that includes 50 AI-responses per month."
    },
    {
        id: 9,
        question: "What analytics does SavvyGPT provide?",
        answer: "SavvyGPT offers basic and advanced analytics, depending on your plan, to help you identify areas for improvement and better understand your customers' needs."
    },
    {
        id: 10,
        question: "Is SavvyGPT scalable for growing businesses?",
        answer: "Yes, SavvyGPT is designed to be infinitely scalable, ensuring that it can adapt to your business's growth and changing needs."
    },
    {
        id: 11,
        question: "How secure is SavvyGPT?",
        answer: "SavvyGPT takes data security seriously and employs industry-standard practices to protect your information."
    },
    {
        id: 12,
        question: "What kind of support does SavvyGPT offer?",
        answer: "SavvyGPT offers various support options, including priority support and dedicated account managers, depending on your chosen plan."
    }
];
const FaqQuestion = () => {
    return (
        <>
            <div className="sc-faq-pages-area sc-pt-130 sc-md-pt-70 sc-pb-10 sc-md-pb-10">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 offset-lg-3">
                            <div className="sc-heading text-center sc-mb-60">
                                <h2 className="sc-mb-20">Frequently Asked Questions</h2>
                                <div className="description">Welcome to our FAQ page! We've compiled a list of the most commonly asked questions and their answers to help you better understand SavvyGPT and its features. If you can't find the information you're looking for, feel free to reach out to our support team for further assistance.</div>
                            </div>
                        </div>
                        <div className="col-lg-8 offset-lg-2">
                            <div className="accordion sc-faq-style" id="accordionExample">
                                {faqData.map((item, index) => (
                                   <div className="accordion-item" key={item.id}>
                                       <h2 className="accordion-header" id={`heading${index}`}>
                                           <button
                                              className="accordion-button"
                                              type="button"
                                              data-bs-toggle="collapse"
                                              data-bs-target={`#collapse${index}`}
                                              aria-expanded={index === 0 ? "true" : "false"}
                                              aria-controls={`collapse${index}`}
                                           >
                                               {item.question}
                                           </button>
                                       </h2>
                                       <div id={`collapse${index}`} className={`accordion-collapse collapse${index === 0 ? " show" : ""}`} aria-labelledby={`heading${index}`} data-bs-parent="#accordionExample">
                                           <div className="accordion-body">
                                               <strong>{item.answer}</strong>
                                           </div>
                                       </div>
                                   </div>
                                ))}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FaqQuestion;
