import React, {useContext} from 'react';
import { useLocation } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import {UserContext} from "../../lib/contexts";
import {DemoContext} from "../../lib/contexts/DemoContext";
import {useMediaQuery, useTheme} from "@mui/material";

const MenuItems = (props) => {
    const { parentMenu } = props;
   const theme = useTheme();
   const matchSM = useMediaQuery(theme.breakpoints.down('sm'));
   const matchMD = useMediaQuery(theme.breakpoints.down('md'));
    const location = useLocation();
   const {
      state: {
         user_id
      }
   } = useContext(UserContext);

    return (
        <>
           {!user_id && <>
              <li className={`canvas sc-sm-pr-10 ${matchSM ? 'sc-pr-10' : 'sc-pr-20'} text-white`}>
                 <Link className={parentMenu === 'home' ? 'menu-item active' : 'menu-item'} to="/#">Features</Link>
              </li>
              <li className={`canvas sc-sm-pr-10 ${matchSM ? 'sc-pr-10' : 'sc-pr-20'} text-white`}>
                 <Link className={location.pathname === '/pricing' ? 'active' : ''} to="../../pricing#">Pricing</Link>
              </li>
              <li className={'d-none'}>
                 <Link className={location.pathname === '/docs' ? 'active' : ''} to="../../docs#">API Documentation</Link>
              </li>
              <li className={`canvas sc-sm-pr-10 ${matchSM ? 'sc-pr-10' : 'sc-pr-20'} text-white`}>
                 <Link className={location.pathname === '/faq' ? 'active' : ''} to="../../faq#">FAQ</Link>
              </li>
           </>}
           {user_id && <>
              <li className={`canvas sc-sm-pr-10 ${matchSM ? 'sc-pr-10' : 'sc-pr-20'} text-white`}>
                 <Link className={(location.pathname === '/' || location.pathname === '/dashboard') ? 'active' : ''}  to="../../dashboard">Dashboard</Link>
              </li>
              <li className={`canvas sc-sm-pr-10 ${matchSM ? 'sc-pr-10' : 'sc-pr-20'} text-white`}>
                 <Link className={location.pathname.startsWith('/dashboard/faqs') ? 'active' : ''} to="../../dashboard/faqs">Manage FAQs</Link>
              </li>
              <li className={`canvas sc-sm-pr-10 ${matchSM ? 'sc-pr-10' : 'sc-pr-20'} text-white`}>
                 <Link className={location.pathname === '/dashboard/customize' ? 'active' : ''} to="../../dashboard/customize">Customize</Link>
              </li>
              <li className={`canvas sc-sm-pr-10 ${matchSM ? 'sc-pr-10' : 'sc-pr-20'} text-white`}>
                 <Link className={location.pathname.startsWith('/dashboard/contacts') ? 'active' : ''}
                       to="../../dashboard/contacts">Contacts</Link>
              </li>
           </>}
           {/*
            <li>
                <Link className={location.pathname === '/signup' ? 'active' : ''} to="/signup#">Signup</Link>
            </li>
            <li className="menu-item-has-children">
                <Link className={parentMenu === 'pages' ? 'menu-item-has-children active' : 'menu-item-has-children'} to="#">Pages</Link>
                <ul className="sub-menu-list">
                    <li><Link className={location.pathname === '/services' ? 'active' : ''} to="/services#">Service</Link></li>
                    <li><Link className={location.pathname === '/pricing' ? 'active' : ''} to="/pricing#">Price</Link></li>
                    <li><Link className={location.pathname === '/team' ? 'active' : ''} to="/team#">Team</Link></li>
                    <li><Link className={location.pathname === '/team-details' ? 'active' : ''} to="/team-details#">Team Details</Link></li>
                    <li><Link className={location.pathname === '/faq' ? 'active' : ''} to="/faq#">Faq</Link></li>
                    <li><Link className={location.pathname === '/login' ? 'active' : ''} to="/login#">Login</Link></li>
                    <li><Link className={location.pathname === '/signup' ? 'active' : ''} to="/signup#">Sign Up</Link></li>
                    <li><Link className={location.pathname === '/error' ? 'active' : ''} to="/error#">404 Error</Link></li>
                </ul>
            </li>
            <li className="menu-item-has-children">
                <Link className={parentMenu === 'blog' ? 'menu-item-has-children active' : 'menu-item-has-children'} to="/blog#">Blog</Link>
                <ul className="sub-menu-list">
                    <li><Link className={location.pathname === '/blog' ? 'active' : ''} to="/blog#">Blog</Link></li>
                    <li><Link className={location.pathname === '/blog-details' ? 'active' : ''} to="/blog-details#">Blog Details</Link></li>
                </ul>
            </li>
            <li><Link className={location.pathname === '/contact' ? 'active' : ''} to="/contact#">Contact</Link></li>
        */}
        </>
    );
}

export default MenuItems;
