import axios from 'axios';
import useCurrentUser from '../hooks/useCurrentUser';
import { API_HOST } from '../constants/constants';
export const postAutoFill = async (token, userID, prompt, details) => {
  if(!details || !prompt){
    return false
  }
  const apiURL = API_HOST + '/autofill';
  try {
    const { data } = await axios.post(apiURL,{
      promptType:String(prompt),
      details: details,
    },{
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+token,
        'x-gg-user-id': userID
      }
    });
    return data;
  } catch (error) {
    console.error(error);
  }
};
