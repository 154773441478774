import React, {useState, useMemo} from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import BlogSidebar from './BlogSidebar';
import useBlogPosts from "../../../lib/hooks/useBlogPosts";
import {CircularProgress, Typography} from "@mui/material";
import {prettyDateLong, toUnderScoreSlug, truncate} from "../../../lib/helpers/helpers";

const BlogNews = () => {
    const {data:blogPosts, isLoading} = useBlogPosts()
    const [search, setSearch] = useState('');
    const filteredBlogPosts = useMemo(() => {
        return (
           blogPosts?.filter(
              item => (
                 item.title?.toLowerCase().includes(search.toLowerCase()) ||
                 item.content?.toLowerCase().includes(search.toLowerCase()) ||
                 item.category?.toLowerCase().includes(search.toLowerCase()) ||
                 (item.tags && item.tags.toLowerCase().includes(search.toLowerCase()))
              )
           ) || []
        );
    }, [blogPosts, search]);
    
    return (
        <>
            <div className="sc-blog-pages-area sc-pt-140 sc-md-pt-80 sc-pb-20 sc-md-pb-20">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            {isLoading && <CircularProgress />}
                            {!Boolean(filteredBlogPosts.length) && <Typography variant={'h5'} color={'textSecondary'} style={{textAlign:'center'}}>No articles found.</Typography>}
                            {!isLoading && Boolean(filteredBlogPosts.length) && filteredBlogPosts.map(post=>{
                                return <div key={post.id} className="blog-item sc-mb-30">
                                    <div className="blog-image">
                                        <Link to={`../../blog/${toUnderScoreSlug(post.title)}`}><img src={post.image} alt={post.title} /></Link>
                                    </div>
                                    <div className="blog-content text-center">
                                        <div className="blog_date">
                                            <ul>
                                               {/* <li>01</li>
                                                <li>Jan</li>*/}
                                                <li>{prettyDateLong(post.created_at)}</li>
                                            </ul>
                                        </div>
                                        <div className="blog-list-menu list_menu">
                                            <ul className="blog-list">
                                                <li><i className="ri-price-tag-3-fill"></i><Link to="#"> {truncate(post.tags,75)}</Link></li>
                                            </ul>
                                        </div>
                                        <h3 className="blog-title sc-pt-15 sc-mb-15 fw-bold">
                                            <Link to={`../../blog/${toUnderScoreSlug(post.title)}`}>{post.title}</Link>
                                        </h3>
                                        <div className="description sc-mb-20">
                                            {truncate(post.content, 250)}
                                        </div>
                                        <Link to={`../../blog/${toUnderScoreSlug(post.title)}`} className="readmore">Read More </Link>
                                    </div>
                                </div>
                            })}
                        </div>
                        <div className="col-lg-4">
                            <BlogSidebar search={search} setSearch={setSearch} />
                        </div>
                    </div>
                    
                  {/*
                    <div className="spria-navigation text-center sc-md-pt-70">
                        <ul>
                            <li><Link to="#" className="active">1</Link></li>
                            <li><Link to="#">2</Link></li>
                            <li><Link to="#">3</Link></li>
                            <li><Link to="#">4</Link></li>
                            <li><Link to="#">5</Link></li>
                            <li><Link to="#" className="arrow arrow-line"><i className="ri-arrow-right-line"></i></Link>
                            </li>
                        </ul>
                    </div>*/}
                </div>
            </div>
        </>
    );
};

export default BlogNews;
