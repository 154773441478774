import React, {useContext} from 'react';
import Header from '../../Shared/Header';
import BreadCums from '../../Shared/BreadCums';
import {Grid} from "@mui/material";
import PlanDetails from "./PlanDetails";
import FAQSummary from "./FAQSummary";
import ChartDetails from "./ChartDetails";
import ActivityDetails from "./ActivityDetails";
import {DemoContext} from "../../../lib/contexts/DemoContext";

const Dashboard = () => {
   const {
      state: { matchSM, matchMD },
   } = useContext(DemoContext);
    return (
        <>
            <Header
                parentMenu='pages'
                headerClass='sc-header-section inner-header-style'
                signUpBtn='inner-btn'
            />
            <BreadCums pageTitle='Dashboard' empty />
           <section className={`${matchSM ? '' :'sc-feature-section-area5'} sc-pt-30 sc-md-pt-30 sc-pb-140 sc-md-pb-70`}>
              <div className="container">
                 <div className="row align-items-center">
                    <Grid container item xs={12} spacing={matchSM ? 2: 4} style={{margin:'auto'}}>
                       <Grid item xs={matchSM ? 12 : 4}>
                          <PlanDetails/>
                       </Grid>
                       <Grid item xs={matchSM ? 12 : 8}>
                          <FAQSummary summary />
                       </Grid>
                       <Grid item xs={matchSM ? 12 : matchMD ? 7 : 8}>
                          <ChartDetails/>
                       </Grid>
                       <Grid item xs={matchSM ? 12 : matchMD ? 5 : 4}>
                          <ActivityDetails/>
                       </Grid>
                    </Grid>
                 </div>
              </div>
           </section>
        </>
    );
};

export default Dashboard;
