import React from 'react';
import { Fade } from '@mui/material';
import { HashLink as Link } from 'react-router-hash-link';
import sectionBg4 from '../../../assets/images/banner/section-bg4.png'
import sectionBg5 from '../../../assets/images/banner/section-bg5.png'
import sectionBg6 from '../../../assets/images/banner/section-bg6.png'
import sectionBg7 from '../../../assets/images/banner/section-bg7.png'
import mediaImage from '../../../assets/images/feature/media-image.png'
import mediaImage1 from '../../../assets/images/feature/media-image1.png'

const Home4Media = () => {
    return (
        <>
            <section className="section-bg-shape1">
                <div className="sc-media-section-area p-z-idex sc-pb-140 sc-md-pb-80 sc-sm-pb-40">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <Fade in={true}>
                                    <div className="sc-media-item p-z-idex sc-pr-110 sc-md-pr-0 sc-sm-pr-0">
                                        <div className="sc-heading-area sc-mb-20">
                                            <h2 className="heading-title">Enjoy the <span className="gradiant-color">benefits</span> of AI Live Chat</h2>
                                        </div>
                                        <div className="description sc-mb-20">
                                            AI Live Chat, powered by SavvyGPT, revolutionizes customer support by harnessing advanced artificial intelligence. This innovative solution provides numerous advantages to both businesses and customers, including:
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="sc-media-list">
                                                    <ul>
                                                        <li><i className="ri-checkbox-circle-fill"></i> Immediate assistance</li>
                                                        <li><i className="ri-checkbox-circle-fill"></i> Reduce wait times</li>
                                                        <li><i className="ri-checkbox-circle-fill"></i> Personalized responses</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="sc-media-list">
                                                    <ul>
                                                        <li><i className="ri-checkbox-circle-fill"></i> Infinitely Scalable</li>
                                                        <li><i className="ri-checkbox-circle-fill"></i> Reduce workload for staff</li>
                                                        <li><i className="ri-checkbox-circle-fill"></i> Available 24/7</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="media-btn sc-mt-50">
                                            <Link className="gradiant-btn" to="/signup#">
                                                <span>Get Started</span>
                                            </Link>
                                        </div>
                                    </div>
                                </Fade>
                            </div>
                            <div className="col-lg-6">
                                <Fade in={true} timeout={250}>
                                    <div className="sc-media-images shake-y sc-md-mt-75">
                                        <img src={mediaImage} alt="Images" />
                                    </div>
                                </Fade>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sc-media-section-two p-z-idex sc-pb-130 sc-md-pb-40 sc-sm-pb-10">
                    <div className="container">
                        <div className="row align-items-center flex-lg-row-reverse">
                            <div className="col-lg-6">
                                <Fade in={true}>
                                    <div className="sc-media-two p-z-idex sc-pl-95 sc-md-pr-0 sc-sm-pr-0">
                                        <div className="sc-heading-area sc-mb-20">
                                            <h2 className="heading-title">The Best <span className="gradiant-color"> Features </span> To Boost Your
                                                Customer Success
                                            </h2>
                                        </div>
                                        <div className="description sc-mb-15">SavvyGPT's robust features are designed to boost your customer success. The AI-driven live chat, combined with the Knowledge Base, ensures that customers can find the information they need quickly and efficiently. This results in higher satisfaction rates and increased brand loyalty.</div>
                                        <div className="description sc-mb-35">
                                            Our auto-translation capabilities enable businesses to cater to a global audience, expanding their reach and fostering positive customer experiences across different languages and cultures.
                                            <br/><br/>
                                            The advanced analytics provide valuable insights into customer behavior, allowing businesses to identify areas for improvement, optimize support processes, and ultimately drive customer success to new heights. With SavvyGPT, businesses can enhance their customer support and build lasting relationships with their users.
                                        </div>
                                        <div className="media-btn sc-mt-50">
                                            <Link className="gradiant-btn" to="/signup#">
                                                <span>Get Started</span>
                                            </Link>
                                        </div>
                                    </div>
                                </Fade>
                            </div>
                            <div className="col-lg-6 ">
                                <Fade in={true} timeout={250}>
                                    <div className="sc-media-images shake-y p-z-idex sc-md-mt-30 sc-pr-30 sc-md-pr-0 sc-md-mb-50">
                                        <img src={mediaImage1} alt="Images" />
                                    </div>
                                </Fade>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sc-section-bg1">
                    <img className="bg-image-5" src={sectionBg4} alt="Bg" />
                    <img className="bg-image-6" src={sectionBg5} alt="Bg" />
                    <img className="bg-image-7" src={sectionBg6} alt="Bg" />
                    <img className="bg-image-8" src={sectionBg7} alt="Bg" />
                </div>
            </section>
        </>
    );
};

export default Home4Media;
